import { EndPoint, getURL, Request } from '@model/service';
import uri from 'urijs';
import {
  AuthenticatePayload,
  AuthenticateResponse,
  BookingsPayload,
  BookingsResponse,
  ClaimIncentivesPayload,
  FamTripCompetitionPayload,
  GetAgentsResponse,
  RegisterPayload,
  ResetPasswordPayload,
  ValidatePayload,
  ChangePasswordPayload
} from '@model/agents/agents-models';
import { MyBookingDetails } from '@model/mmb';
import { DataEnvelope } from '@model/iceberg';

interface TradeApiRequests {
  get: () => Promise<GetAgentsResponse>;
  authenticate: (payload: AuthenticatePayload) => Promise<any>;
  logout: () => Promise<any>;
  resetPassword: (payload: ResetPasswordPayload) => Promise<any>;
  changePassword: (payload: ChangePasswordPayload) => Promise<any>;
  validate: (payload: ValidatePayload) => Promise<any>;
}

export class TradeApi implements TradeApiRequests {
  private api: Request = new Request();

  public async get(): Promise<GetAgentsResponse> {
    return await this.api.get(uri(getURL()).path(EndPoint.TRADE).href());
  }

  public async authenticate(payload: AuthenticatePayload): Promise<AuthenticateResponse> {
    return await this.api.post(uri(getURL()).path(EndPoint.AUTHENTICATE).href(), payload);
  }

  public async logout(): Promise<any> {
    return await this.api.post(uri(getURL()).path(EndPoint.LOGOUT).href());
  }

  public async register(payload: RegisterPayload): Promise<any> {
    return await this.api.post(uri(getURL()).path(EndPoint.REGISTER).href(), payload);
  }

  public async bookings({ page, limit, filter }: BookingsPayload): Promise<BookingsResponse> {
    return await this.api.get(uri(getURL()).path(EndPoint.BOOKINGS).search({ page, limit, filter }).href());
  }

  public async claimIncentives(payload: ClaimIncentivesPayload): Promise<any> {
    return await this.api.post(uri(getURL()).path(EndPoint.CLAIM_INCENTIVES).href(), payload);
  }

  public async booking(id: string): Promise<DataEnvelope<MyBookingDetails>> {
    return await this.api.get(
      uri(getURL())
        .path(EndPoint.BOOKINGS.concat(`/${id}`))
        .href()
    );
  }

  public async pollBooking(
    id: string,
    retryIf: (bookingDetails: any) => boolean
  ): Promise<DataEnvelope<MyBookingDetails>> {
    const query = uri(getURL())
      .path(EndPoint.BOOKINGS.concat(`/${id}`))
      .href();
    return await this.api.poll(query, { maxAttempts: 10, interval: 400, retryIf });
  }

  public async resetPassword(payload: ResetPasswordPayload): Promise<any> {
    return await this.api.post(uri(getURL()).path(EndPoint.TRADE.concat(EndPoint.RESET_PASSWORD)).href(), payload);
  }

  public async validate({ token }: ValidatePayload): Promise<any> {
    return await this.api.get(
      uri(getURL()).path(EndPoint.TRADE.concat(EndPoint.VALIDATE_TOKEN)).search({ token }).href()
    );
  }

  public async changePassword(payload: ChangePasswordPayload): Promise<any> {
    return await this.api.post(uri(getURL()).path(EndPoint.TRADE.concat(EndPoint.CHANGE_PASSWORD)).href(), payload);
  }

  public async famTripCompetition(payload: FamTripCompetitionPayload): Promise<any> {
    return await this.api.post(uri(getURL()).path(EndPoint.FAM_TRIP_COMPETITION).href(), payload);
  }
}
