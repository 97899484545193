import { compact, map, isNull, isString } from 'lodash';
import { isServer } from '@util/is-server';

export interface MediaQueries {
  small: string;
  medium: string;
  large: string;
  xLarge: string;
  ie: (breakpoint?: number) => string;
  moz: string;
  safari: string;
  x2: string;
  x3: string;
  x4: string;
  portrait: string;
  landscape: string;
}

export interface Breakpoints {
  small: number;
  medium: number;
  large: number;
  xLarge: number;
}

export const breakpoints: Breakpoints = {
  small: 600,
  medium: 960,
  large: 1280,
  xLarge: 1920
};

export const getBreakpoints: () => number[] = () => [
  breakpoints.small,
  breakpoints.medium,
  breakpoints.large,
  breakpoints.xLarge
];

const getDimension = (d: number | string) => (isString(d) ? d : `${d}vw`);

const sizeDefaults = { sizes: [100, 50, 25], fallback: '294px' };

export const getSizes = (sizes?: Array<number | string | null>, fallback?: number | string): string => {
  const breakpoints = getBreakpoints();
  const sizeValues = sizes
    ? {
        sizes,
        fallback
      }
    : sizeDefaults;
  return compact(
    map(sizeValues.sizes, (size, idx) =>
      isNull(size) ? null : `(max-width: ${breakpoints[idx]}px) ${getDimension(size)}`
    )
  )
    .concat(sizeValues.fallback ? getDimension(sizeValues.fallback) : [])
    .join(', ');
};

export const mq: MediaQueries = {
  small: `@media (min-width: ${breakpoints.small}px)`,
  medium: `@media (min-width: ${breakpoints.medium}px)`,
  large: `@media (min-width: ${breakpoints.large}px)`,
  xLarge: `@media (min-width: ${breakpoints.xLarge}px)`,
  ie: (breakpoint: number = 0) =>
    `@media (min-width: ${breakpoint}px) and (-ms-high-contrast: none), (-ms-high-contrast: active)`, // this hack allows to target IE10+,
  moz: '@supports (-moz-appearance:none)', // this hack allows to target FireFox
  safari: '@media not all and (min-resolution:.001dpcm)', // this hack allows to target Safari
  x2: `@media only screen and (-o-min-device-pixel-ratio: 5/4),
  only screen and (-webkit-min-device-pixel-ratio: 1.25),
  only screen and (min-device-pixel-ratio: 1.25),
  only screen and (min-resolution: 1.25dppx)`,
  x3: `@media only screen and (-o-min-device-pixel-ratio: 9/4),
  only screen and (-webkit-min-device-pixel-ratio: 2.25),
  only screen and (min-device-pixel-ratio: 2.25),
  only screen and (min-resolution: 2.25dppx)`,
  x4: `@media only screen and (-o-min-device-pixel-ratio: 13/4),
  only screen and (-webkit-min-device-pixel-ratio: 3.25),
  only screen and (min-device-pixel-ratio: 3.25),
  only screen and (min-resolution: 3.25dppx)`,
  portrait: `@media only screen and (orientation:portrait)`,
  landscape: `@media only screen and (orientation:landscape)`
};

export const isMobile = (width?: number) => !isServer && (width || window.innerWidth) <= breakpoints.small;
export const isTablet = (width?: number) =>
  !isServer && (width || window.innerWidth) > breakpoints.small && (width || window.innerWidth) <= breakpoints.medium;
export const isDesktop = (width?: number) => !isServer && (width || window.innerWidth) >= breakpoints.large;
