import { PaymentFormValues, AddressFormValues, PaymentMethod } from '@model/forms';

export enum PaymentActions {
  ON_ADDRESS_FORM_CHANGE = '@PAYMENT_FORM/ON_ADDRESS_FORM_CHANGE',
  ON_PAYMENT_FORM_CHANGE = '@PAYMENT_FORM/ON_PAYMENT_FORM_CHANGE',
  RESET_PAYMENT_FORM = '@PAYMENT_FORM/RESET_PAYMENT_FORM'
}

export const INITIAL_PAYMENT_FORM_STATE: PaymentFormValues = {
  contactByPost: false,
  companyName: '',
  buildingNameOrNumber: '',
  street: '',
  address: '',
  address2: '',
  townCity: '',
  county: '',
  postCode: '',
  country: '',
  countryIso2: '',
  discountCode: '',
  paymentMethod: PaymentMethod.FULL_AMOUNT,
  cardNumber: '',
  cardExpiryDate: '',
  cvv: '',
  termsAccepted: false,
  nameOnCard: '',
  amount: '',
  totalOutstandingAmount: 0,
  tradeEmail: '',
  cardholderName: ''
};

export const onAddressFormChange = (payload: AddressFormValues) => ({
  type: PaymentActions.ON_ADDRESS_FORM_CHANGE,
  payload
});

export const onPaymentFormChange = (payload: PaymentFormValues) => ({
  type: PaymentActions.ON_PAYMENT_FORM_CHANGE,
  payload
});

export const resetPaymentForm = () => ({
  type: PaymentActions.RESET_PAYMENT_FORM
});

export const paymentFormReducer: any = (state: PaymentFormValues = INITIAL_PAYMENT_FORM_STATE, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case PaymentActions.ON_ADDRESS_FORM_CHANGE:
      return { ...state, ...payload };
    case PaymentActions.ON_PAYMENT_FORM_CHANGE:
      return { ...state, ...payload };
    case PaymentActions.RESET_PAYMENT_FORM:
      return INITIAL_PAYMENT_FORM_STATE;
    default:
      return state;
  }
};
