import { Page } from '@model/common/pages';
import { DestinationHrefBuilder } from './destination-href-builder';
import { TripTypes } from '@model/contentful';
import { isPrivateTour } from '@util/tours';

export interface LinkItem {
  as: string;
  href: string;
}

export class DestinationLinkBuilder {
  private SEPARATOR: string = '/';
  private DEFAULT_LINK_ITEM: LinkItem = {
    as: '',
    href: ''
  };
  private getTripTypeUrlSuffix: ({ tripType, isHref }: { tripType: TripTypes; isHref?: boolean }) => string = ({
    tripType,
    isHref
  }: {
    tripType: TripTypes;
    isHref?: boolean;
  }) => {
    switch (tripType) {
      case TripTypes.ALL:
        return '';
      case TripTypes.HOLIDAYS:
        return `${this.SEPARATOR}${tripType}`;
      case TripTypes.ADD_ONS:
        return `${this.SEPARATOR}${TripTypes.HOLIDAYS}${Page.EXPERIENCES}`;
      default: {
        const tripTypeSlug = isHref ? `[tripType]` : tripType;
        return `${this.SEPARATOR}${TripTypes.HOLIDAYS}${this.SEPARATOR}${tripTypeSlug}`;
      }
    }
  };
  private buildHotelPath: (paths: Array<string>) => string = (paths: Array<string>) => {
    return paths.reduce(
      (acc: string, path: string, index: number) =>
        acc +
        (index !== 4 ? `${index > 0 ? this.SEPARATOR : ''}${path}` : `${this.SEPARATOR}hotels${this.SEPARATOR}${path}`),
      ''
    );
  };
  public getDestinationLink(path?: string, tripType?: TripTypes, page?: Page): LinkItem {
    if (!path) return this.DEFAULT_LINK_ITEM;
    const paths: Array<string> = path.split(this.SEPARATOR);
    const level: number = paths.length;
    const isHotel = level === 5;
    const linkPath = !isHotel ? path : this.buildHotelPath(paths);
    const hrefBuilder: DestinationHrefBuilder = new DestinationHrefBuilder();
    return {
      href: `${hrefBuilder.get(level)}${tripType ? this.getTripTypeUrlSuffix({ tripType, isHref: true }) : ''}${
        page || ''
      }`,
      as: `${Page.DESTINATION}${this.SEPARATOR}${linkPath}${tripType ? this.getTripTypeUrlSuffix({ tripType }) : ''}${
        page || ''
      }`
    };
  }

  public getTourLink(tourPath: string, tripType?: TripTypes): LinkItem {
    const pathElements = tourPath.split('/');
    const tourName = pathElements.pop();
    const path = pathElements.join('/');
    const isPrivate = isPrivateTour(tripType);
    // const pageSlug = tripType === TripTypes.MULTI_CENTRE ? '[multicentre]' : '[tourName]';
    const pageSlug = '[tourName]';
    const destinationLink = this.getDestinationLink(path, tripType);

    return {
      href: `${destinationLink.href}${this.SEPARATOR}${pageSlug}`,
      as: `${destinationLink.as}${this.SEPARATOR}${tourName}${
        isPrivate && !/-private$/.test(tourName || '') ? '-private' : ''
      }`
    };
  }

  public getResortsLink(path: string): LinkItem {
    if (!path) return this.DEFAULT_LINK_ITEM;
    const paths: Array<string> = path.split(this.SEPARATOR);
    const level: number = paths.length;
    const hrefBuilder: DestinationHrefBuilder = new DestinationHrefBuilder();
    return {
      href: hrefBuilder.get(level).concat(Page.RESORTS),
      as: `${Page.DESTINATION}${this.SEPARATOR}${path}${Page.RESORTS}`
    };
  }

  public getRegionLink(path: string): LinkItem {
    if (!path) return this.DEFAULT_LINK_ITEM;
    const paths: Array<string> = path.split(this.SEPARATOR);
    const level: number = paths.length;
    const hrefBuilder: DestinationHrefBuilder = new DestinationHrefBuilder();
    return {
      href: hrefBuilder.get(level).concat(Page.REGIONS),
      as: `${Page.DESTINATION}${this.SEPARATOR}${path}${Page.REGIONS}`
    };
  }

  public getGuidesLink(path: string, guidePage: Page, tripType?: TripTypes): LinkItem {
    const link: LinkItem = this.getDestinationLink(path, tripType);
    return {
      href: link.href.concat(guidePage),
      as: link.as.concat(guidePage)
    };
  }

  public getGuideLink(path: string, guidePath: string, guidePage: Page, tripType?: TripTypes): LinkItem {
    const link: LinkItem = this.getGuidesLink(path, guidePage, tripType);
    const href: string = this.getGuideHref(guidePage);
    return {
      href: link.href.concat(`${this.SEPARATOR}${href}`),
      as: link.as.concat(`${this.SEPARATOR}${guidePath}`)
    };
  }

  public getDestinationHotelsLink(path: string): LinkItem {
    const link: LinkItem = this.getDestinationLink(path);
    return {
      href: link.href.concat(`${this.SEPARATOR}hotels`),
      as: link.as.concat(`${this.SEPARATOR}hotels`)
    };
  }

  private getGuideHref(page: Page) {
    switch (page) {
      case Page.EXPERIENCES:
        return '[experience]';
      case Page.TRAVEL_GUIDES:
        return '[travelGuide]';
      case Page.VIDEO_GUIDES:
        return '[videoGuide]';
      default:
        return '';
    }
  }
}
