import { SortOptions } from '@model/common';
import { Months } from '@model/common/dates';
import { GlobalAppState } from '@model/state';
import { TripTypes } from '@model/contentful';

export const getSelectedSortOption: (state: GlobalAppState) => SortOptions = (state: GlobalAppState) =>
  state.navigation.selectedSortOption;
export const getTripTypeFilter: (state: GlobalAppState) => TripTypes = (state: GlobalAppState) =>
  state.navigation.selectedFilter;
export const getSelectedMonth: (state: GlobalAppState) => Months = (state: GlobalAppState) =>
  state.navigation.selectedMonth;
export const getSelectedAirport: (state: GlobalAppState) => string = (state: GlobalAppState) =>
  state.navigation.selectedAirport;
