import { call, put, takeEvery } from 'redux-saga/effects';
import { GuestFormValues } from '@model/forms';
import { GuestMapper } from '@model/iceberg/booking/basket/util/guest-mapper';
import { GuestsApi, GuestsResponse } from '@model/iceberg/service/booking/guests-api';
import { Page } from '@model/common';
import { Guest } from '@model/iceberg/booking/basket/guest/guest';
import { navigate } from '../basket/basketActions';
import { HYDRATE } from 'next-redux-wrapper';
import { isEqual } from 'lodash';
import { DealFinderResultsActions } from '@state/deal-finder-results/dealFinderResultsOperations';
import { ToursActions } from '@state/search-results/tours/toursOperations';
import { PackageReferencesActions } from '@state/app';
import { TourReferencesActions } from '@state/tours/tour-references';
import Cookies, { Cookie } from '@model/common/cookie/cookie';

export enum GuestsActions {
  PERFORM_FETCH_GUESTS = '@GUESTS/PERFORM_FETCH_GUESTS',
  UPDATE_GUEST_FORM = '@GUESTS/UPDATE_GUEST_FORM',
  SET_GUESTS = '@GUESTS/SET_GUESTS',
  RECEIVE_GUESTS_SUCCESS = '@GUESTS/RECEIVE_GUESTS_SUCCESS',
  RECEIVE_GUESTS_FAILURE = '@GUESTS/RECEIVE_GUESTS_FAILURE'
}

export const INITIAL_GUESTS_STATE: Array<GuestFormValues> = [];

export const updateGuestForm = (payload: Array<GuestFormValues>) => ({
  type: GuestsActions.UPDATE_GUEST_FORM,
  payload
});

export const setGuests = (searchToken: string, guests: Array<GuestFormValues>) => ({
  type: GuestsActions.SET_GUESTS,
  searchToken,
  guests
});

export const performFetchGuests = (searchToken: string) => ({
  type: GuestsActions.PERFORM_FETCH_GUESTS,
  searchToken
});

export const receiveGuestsSuccess = (payload: Array<GuestFormValues>) => ({
  type: GuestsActions.RECEIVE_GUESTS_SUCCESS,
  payload
});

export const receiveGuestsFailure = () => ({
  type: GuestsActions.RECEIVE_GUESTS_FAILURE
});

export function* onFetchGuests() {
  yield takeEvery([GuestsActions.PERFORM_FETCH_GUESTS], handleFetchGuests);
}

export function* onSetGuests() {
  yield takeEvery([GuestsActions.SET_GUESTS], handleSetGuests);
}

export function* handleSetGuests({ searchToken, guests }: any) {
  const payload: Array<Guest> = GuestMapper.getGuestsPayload(guests);
  const { response } = yield call(putGuests, searchToken, payload);
  if (response) {
    yield put(receiveGuestsSuccess(GuestMapper.getGuestFormValues(response.guests)));
    const page = new Cookies().get(Cookie.ADYEN_ENABLED) ? Page.PAYMENT_ADYEN : Page.PAYMENT;
    yield put(navigate(page));
  } else {
    yield put(receiveGuestsFailure());
  }
}

export function* handleFetchGuests({ searchToken }: any) {
  const { response } = yield call(fetchGuests, searchToken);
  if (response) {
    yield put(receiveGuestsSuccess(GuestMapper.getGuestFormValues(response.guests)));
  } else {
    yield put(receiveGuestsFailure());
  }
}

export function fetchGuests(token: string) {
  return new GuestsApi()
    .get(token)
    .then((response: GuestsResponse) => ({
      response
    }))
    .catch((error: any) => ({
      error
    }));
}

export function putGuests(token: string, guests: Array<Guest>) {
  return new GuestsApi()
    .put(token, guests)
    .then((response: GuestsResponse) => ({
      response
    }))
    .catch((error: any) => ({
      error
    }));
}

export const guestsReducer: any = (state: any = INITIAL_GUESTS_STATE, action: any) => {
  switch (action.type) {
    case HYDRATE:
      if (isEqual(state, INITIAL_GUESTS_STATE)) {
        return action.payload.guests || state;
      }
      return state;
    case GuestsActions.RECEIVE_GUESTS_SUCCESS:
    case GuestsActions.UPDATE_GUEST_FORM:
      return action.payload;
    case DealFinderResultsActions.CLEAR_RESULTS:
    case PackageReferencesActions.SET_PACKAGE_REFERENCES:
    case ToursActions.FETCH_TOURS:
    case TourReferencesActions.SET_TOUR_REFERENCES:
      return INITIAL_GUESTS_STATE;
    default:
      return state;
  }
};
