import { combineReducers } from 'redux';
import { tourReferencesReducer } from './tour-references';
import { tourAvailabilityReducer } from './tour-availability';
import { tourFiltersReducer } from './tour-filters';
import { tourDossiersReducer } from './tour-dossiers';
import { tourSummaryReducer } from './tour-summary';

export const combinedToursReducers = combineReducers({
  tourReferences: tourReferencesReducer,
  tourAvailability: tourAvailabilityReducer,
  tourFilters: tourFiltersReducer,
  tourDossiers: tourDossiersReducer,
  tourSummary: tourSummaryReducer
});

export type CombinedToursReducersType = ReturnType<typeof combinedToursReducers>;
