import { MyBookingLoginFormPayload } from '@model/forms';
import { MyBookingResponse, MyBookingToken, MyBookingDetails } from '@model/mmb';
import { Request, getURL, EndPoint, MyBookingEndpoints } from '@model/service';
import uri from 'urijs';
import { MyBookingDetailsRequestPayload, MyBookingDetailsPollPayload, MyBookingPaymentPayload } from '@state/mmb';

interface MyBookingApiRequests {
  getMyBookingToken: (payload: MyBookingLoginFormPayload) => Promise<MyBookingResponse<MyBookingToken>>;
  getMyBookingDetails: (payload: MyBookingDetailsRequestPayload) => Promise<MyBookingResponse<MyBookingDetails>>;
  pollMyBookingDetails: (payload: MyBookingDetailsPollPayload) => Promise<MyBookingResponse<MyBookingDetails>>;
  performMyBookingPayment: (bookingId: number, payload: MyBookingPaymentPayload) => Promise<any>;
}

export class MyBookingApi implements MyBookingApiRequests {
  private api: Request = new Request();

  public async getMyBookingToken(payload: MyBookingLoginFormPayload): Promise<MyBookingResponse<MyBookingToken>> {
    const { bookingId } = payload;

    return await this.api.post(uri(getURL()).path(`${EndPoint.MMB_BOOKING}/${bookingId}`).href(), payload);
  }

  public async getMyBookingDetails({
    bookingId,
    loginToken
  }: MyBookingDetailsRequestPayload): Promise<MyBookingResponse<MyBookingDetails>> {
    return await this.api.get(
      uri(getURL()).path(`${EndPoint.MMB_BOOKING}/${bookingId}`).search({ loginToken }).href(),
      loginToken
    );
  }

  public async pollMyBookingDetails({
    bookingId,
    loginToken,
    retryIf
  }: MyBookingDetailsPollPayload): Promise<MyBookingResponse<MyBookingDetails>> {
    const query = uri(getURL()).path(`${EndPoint.MMB_BOOKING}/${bookingId}`).search({ loginToken }).href();
    return await this.api.poll(query, { maxAttempts: 10, interval: 400, authToken: loginToken, retryIf });
  }

  public async invalidateBookingLogin(bookingId: number, bookingToken: string): Promise<any> {
    return await this.api.delete(
      uri(getURL()).path(`${EndPoint.MMB_BOOKING}/${bookingId}`).search({ loginToken: bookingToken }).href(),
      bookingToken
    );
  }

  public async performMyBookingPayment(bookingId: number, payload: MyBookingPaymentPayload): Promise<any> {
    return await this.api.post(
      uri(getURL()).path(`${EndPoint.MMB_BOOKING}/${bookingId}${MyBookingEndpoints.PAY}`).href(),
      payload
    );
  }

  public async getBookingInvoice({ bookingId, loginToken }: MyBookingDetailsRequestPayload): Promise<any> {
    return await this.api.get(
      uri(getURL()).path(`${EndPoint.MMB_BOOKING}/${bookingId}${MyBookingEndpoints.INVOICE}`).href(),
      loginToken
    );
  }

  public async getTradeBookingInvoice({ bookingId, loginToken }: MyBookingDetailsRequestPayload): Promise<any> {
    return await this.api.get(
      uri(getURL()).path(`${EndPoint.BOOKINGS}/${bookingId}${MyBookingEndpoints.INVOICE}`).href(),
      loginToken
    );
  }
}
