import {
  FilterNames,
  FiltersSideBarType,
  HotelAccessible,
  SpecialFilters,
  TourTypeToggleOnOption
} from '@model/filters';
import { Flight, FlightType, Package, Route } from '@model/iceberg/booking/package';
import { SelectedFilters } from '@model/state';
import { intersection } from 'lodash';
import { TourDurationFilters, TourSearchItem } from '@model/tours';
import { Result } from '@model/iceberg/deal-finder/deal-finder';
import moment from 'moment';

const OUTSIDE_OF_PRICE_RANGE = 'OUTSIDE_OF_PRICE_RANGE';
const NO_MATCH = 'NO_MATCH';

const filterSearchResults: (
  searchType: FiltersSideBarType,
  searchData: any,
  selectedFiltersNames: Array<FilterNames>
) => any = (searchType: FiltersSideBarType, searchData: any, selectedFiltersNames: Array<FilterNames>) => ({
  [FilterNames.RESORTS]: () => {
    // NOTE: the filter name can theoretically be the same for differnet search types (e.g. flights and hotels)
    // and need to perform different filtering operations
    switch (searchType) {
      case FiltersSideBarType.SEARCH:
        // handle 'resort' filter in product package search
        return searchData.reduce((listOfTokens: Array<string>, productPackage: Package) => {
          if (
            selectedFiltersNames.includes(productPackage.summary.place.resort.display as any) ||
            selectedFiltersNames.includes(SpecialFilters.ALL as any)
          ) {
            return [...listOfTokens, productPackage.token];
          }

          return listOfTokens.length ? listOfTokens : [NO_MATCH];
        }, []);
      default:
        return [];
    }
  },
  [FilterNames.ACCESSIBLE]: () => {
    switch (searchType) {
      case FiltersSideBarType.SEARCH:
        // handle 'accessible' filter in product package search
        return searchData.reduce((listOfTokens: Array<string>, productPackage: Package) => {
          const isHotelAccessible = productPackage.summary.accessible ? HotelAccessible.YES : HotelAccessible.NO;
          if (
            selectedFiltersNames.includes(isHotelAccessible as any) ||
            selectedFiltersNames.includes(SpecialFilters.ALL as any)
          ) {
            return [...listOfTokens, productPackage.token];
          }

          return listOfTokens.length ? listOfTokens : [NO_MATCH];
        }, []);
      default:
        return [];
    }
  },
  [FilterNames.HOTEL_RATING_OCCURENCE]: () => {
    switch (searchType) {
      case FiltersSideBarType.SEARCH:
        // handle 'hotelRatingOccurence' filter in product package search
        return searchData.reduce((listOfTokens: Array<string>, productPackage: Package) => {
          if (
            selectedFiltersNames.includes(productPackage.summary.hotelRating.toString() as any) ||
            selectedFiltersNames.includes(SpecialFilters.ALL as any)
          ) {
            return [...listOfTokens, productPackage.token];
          }

          return listOfTokens.length ? listOfTokens : [NO_MATCH];
        }, []);
      default:
        return [];
    }
  },
  [FilterNames.MEAL_PLANS]: () => {
    switch (searchType) {
      case FiltersSideBarType.SEARCH:
        // handle 'mealPlans' filter in product package search
        return searchData.reduce((listOfTokens: Array<string>, productPackage: Package) => {
          const stringifiedHotelData = JSON.stringify(productPackage);
          let hotelContainsMealPlan = selectedFiltersNames.includes(SpecialFilters.ALL as any);
          selectedFiltersNames.forEach((filterName: FilterNames) => {
            // the hotel data is temporarily converted to lower case due to the backend issue (https://mercuryholidays.atlassian.net/jira/servicedesk/projects/ISD/issue/ISD-60)
            if (!hotelContainsMealPlan && stringifiedHotelData.includes(`"boardBasis":"${filterName}"`)) {
              hotelContainsMealPlan = true;
            }
          });

          return hotelContainsMealPlan
            ? [...listOfTokens, productPackage.token]
            : listOfTokens.length
              ? listOfTokens
              : [NO_MATCH];
        }, []);
      default:
        return [];
    }
  },
  [FilterNames.MINIMUM_PRICE]: () => {
    switch (searchType) {
      case FiltersSideBarType.SEARCH:
        return searchData.reduce((listOfTokens: Array<string>, productPackage: Package) => {
          const cheapestPrice: number = productPackage.cheapestPrice.packagePrice.amount;
          return (!!cheapestPrice && cheapestPrice >= parseInt(selectedFiltersNames[0]) * 100) ||
            selectedFiltersNames.includes(SpecialFilters.ALL as any)
            ? [...listOfTokens, productPackage.token]
            : [...listOfTokens, OUTSIDE_OF_PRICE_RANGE];
        }, []);
      case FiltersSideBarType.TOURS:
        return searchData.reduce((listOfTokens: Array<string>, tour: TourSearchItem) => {
          const tourPrice: number = tour.price.amount;

          return tourPrice >= parseInt(selectedFiltersNames[0]) * 100 ||
            selectedFiltersNames.includes(SpecialFilters.ALL as any)
            ? [...listOfTokens, tour.token]
            : [...listOfTokens, OUTSIDE_OF_PRICE_RANGE];
        }, []);
      default:
        return [];
    }
  },
  [FilterNames.MAXIMUM_PRICE]: () => {
    switch (searchType) {
      case FiltersSideBarType.SEARCH:
        return searchData.reduce((listOfTokens: Array<string>, productPackage: Package) => {
          const cheapestPrice: number = productPackage.cheapestPrice.packagePrice.amount;
          return (!!cheapestPrice && cheapestPrice <= parseInt(selectedFiltersNames[0]) * 100) ||
            selectedFiltersNames.includes(SpecialFilters.ALL as any)
            ? [...listOfTokens, productPackage.token]
            : [...listOfTokens, OUTSIDE_OF_PRICE_RANGE];
        }, []);
      case FiltersSideBarType.TOURS:
        return searchData.reduce((listOfTokens: Array<string>, tour: TourSearchItem) => {
          const tourPrice: number = tour.price.amount;

          return tourPrice <= parseInt(selectedFiltersNames[0]) * 100 ||
            selectedFiltersNames.includes(SpecialFilters.ALL as any)
            ? [...listOfTokens, tour.token]
            : [...listOfTokens, OUTSIDE_OF_PRICE_RANGE];
        }, []);
      default:
        return [];
    }
  },
  [FilterNames.AIRLINE]: () => {
    switch (searchType) {
      case FiltersSideBarType.FLIGHTS:
        return searchData.reduce((listOfTokens: Array<string>, flight: Flight) => {
          const allOutboundAirlines = flight.outbound.reduce(
            (accumulator: any, route: Route) => [...accumulator, route.airline],
            []
          );
          const allReturnAirlines = flight.return.reduce(
            (accumulator: any, route: Route) => [...accumulator, route.airline],
            []
          );

          const containsSelectedAirlines = selectedFiltersNames
            .map(
              (filterName: FilterNames) =>
                allOutboundAirlines.includes(filterName) || allReturnAirlines.includes(filterName)
            )
            .includes(true);

          if (containsSelectedAirlines || selectedFiltersNames.includes(SpecialFilters.ALL as any)) {
            return [...listOfTokens, flight.token];
          }

          return listOfTokens.length ? listOfTokens : [NO_MATCH];
        }, []);
      default:
        return [];
    }
  },
  [FilterNames.DEPARTING_FROM]: () => {
    switch (searchType) {
      case FiltersSideBarType.FLIGHTS:
        return searchData.reduce((listOfTokens: any, flight: Flight) => {
          const outboundDepartureAirport = flight?.outbound[0]?.departure?.code;

          if (
            selectedFiltersNames.includes(outboundDepartureAirport as any) ||
            selectedFiltersNames.includes(SpecialFilters.ALL as any)
          ) {
            return [...listOfTokens, flight.token];
          }

          return listOfTokens.length ? listOfTokens : [NO_MATCH];
        }, []);
      default:
        return [];
    }
  },
  [FilterNames.STOPS]: () => {
    switch (searchType) {
      case FiltersSideBarType.FLIGHTS:
        return searchData.reduce((listOfTokens: any, flight: Flight) => {
          const numberOfOutboundLegs = flight.outbound.length;
          const numberOfReturnLegs = flight.return.length;

          const isDirect = numberOfOutboundLegs === 1 && numberOfReturnLegs === 1;

          if (
            (selectedFiltersNames.includes(FlightType.DIRECT as any) && isDirect) ||
            (selectedFiltersNames.includes(FlightType.INDIRECT as any) && !isDirect) ||
            selectedFiltersNames.includes(SpecialFilters.ALL as any)
          ) {
            return [...listOfTokens, flight.token];
          }

          return listOfTokens.length ? listOfTokens : [NO_MATCH];
        }, []);
      default:
        return [];
    }
  },
  [FilterNames.OUTBOUND_DEPARTURE_TIME]: () => {
    switch (searchType) {
      case FiltersSideBarType.FLIGHTS:
        return searchData.reduce((listOfTokens: any, flight: Flight) => {
          if (!selectedFiltersNames.includes(SpecialFilters.ALL as any)) {
            const [desiredFromHour, desiredToHour] = selectedFiltersNames;
            const outboundDepartureHour = moment(flight.outbound[0].departure.time).parseZone().hour();

            if (
              outboundDepartureHour >= parseInt(desiredFromHour) &&
              outboundDepartureHour <= parseInt(desiredToHour)
            ) {
              return [...listOfTokens, flight.token];
            }

            return listOfTokens.length ? listOfTokens : [NO_MATCH];
          }

          return [...listOfTokens, flight.token];
        }, []);
      default:
        return [];
    }
  },
  [FilterNames.RETURN_DEPARTURE_TIME]: () => {
    switch (searchType) {
      case FiltersSideBarType.FLIGHTS:
        return searchData.reduce((listOfTokens: any, flight: Flight) => {
          if (!selectedFiltersNames.includes(SpecialFilters.ALL as any)) {
            const [desiredFromHour, desiredToHour] = selectedFiltersNames;
            const returnDepartureHour = moment(flight.return[0].departure.time).parseZone().hour();

            if (returnDepartureHour >= parseInt(desiredFromHour) && returnDepartureHour <= parseInt(desiredToHour)) {
              return [...listOfTokens, flight.token];
            }

            return listOfTokens.length ? listOfTokens : [NO_MATCH];
          }

          return [...listOfTokens, flight.token];
        }, []);
      default:
        return [];
    }
  },
  [FilterNames.TOUR_TRIP_TYPE]: () => {
    switch (searchType) {
      case FiltersSideBarType.TOURS:
        return searchData.reduce((listOfTokens: Array<string>, tour: TourSearchItem) => {
          if (
            selectedFiltersNames.includes(tour.tripType as any) ||
            selectedFiltersNames.includes(SpecialFilters.ALL as any)
          ) {
            return [...listOfTokens, tour.token];
          }

          return listOfTokens;
        }, []);
      default:
        return [];
    }
  },
  [FilterNames.TOUR_DURATION]: () => {
    switch (searchType) {
      case FiltersSideBarType.TOURS:
        return searchData.reduce((listOfTokens: Array<string>, tour: TourSearchItem) => {
          if (
            isTourMatchingDurations(tour, selectedFiltersNames) ||
            selectedFiltersNames.includes(SpecialFilters.ALL as any)
          ) {
            return [...listOfTokens, tour.token];
          }

          return listOfTokens;
        }, []);
      default:
        return [];
    }
  },
  [FilterNames.TOUR_TYPE]: () => {
    switch (searchType) {
      case FiltersSideBarType.TOURS:
        return searchData.reduce((listOfTokens: Array<string>, tour: TourSearchItem) => {
          if (
            (selectedFiltersNames.includes(TourTypeToggleOnOption.MAIN_TOURS as any) && !tour.isExtension) ||
            selectedFiltersNames.includes(SpecialFilters.ALL as any)
          ) {
            return [...listOfTokens, tour.token];
          }

          return listOfTokens.length ? listOfTokens : [NO_MATCH];
        }, []);
      default:
        return [];
    }
  }
});

export const getFilteredTokens: (
  searchType: FiltersSideBarType,
  searchData: any,
  selectedFilters: SelectedFilters
) => any = (searchType: FiltersSideBarType, searchData: any, selectedFilters: SelectedFilters) => {
  const selectedFilterTypes: Array<FilterNames> = Object.keys(selectedFilters) as Array<FilterNames>;
  const filteredTokenLists = selectedFilterTypes
    .map((filterType: FilterNames) => {
      if (selectedFilterTypes && selectedFilterTypes.length && searchData && searchType) {
        const filterResults: any = filterSearchResults(searchType, searchData, selectedFilters[filterType] as any);

        if (filterResults[filterType]) {
          // NOTE: the below returns the array of arrays of tokens matching each applied filter
          return filterResults[filterType]();
        }
      }

      return [];
    })
    .filter((tokensList: Array<string>) => tokensList.length);

  /* NOTE: the below combines all the tokens arrays for all
       the applied filters into a single arrayof intersecting tokens */
  return intersection(...filteredTokenLists).filter((token: any) => token !== OUTSIDE_OF_PRICE_RANGE);
};

export const getMinAndMaxPrice = ({ packages }: { packages: Array<Result> }) => {
  if (!packages || !packages.length) {
    return {
      minPrice: 0,
      maxPrice: 0
    };
  }

  const fromPrices = packages
    .map((productPackage: Result) => productPackage.leadInPrice.perPerson.amount)
    .filter((fromPrice: number) => fromPrice);
  return {
    minPrice: Math.min.apply(null, fromPrices) / 100,
    maxPrice: Math.max.apply(null, fromPrices) / 100
  };
};

const isTourMatchingDurations = (tour: TourSearchItem, durationFilters: Array<FilterNames>) => {
  const durationMapper: any = {
    [TourDurationFilters.LESS_THAN_A_WEEK]: {
      minDays: 0,
      maxDays: 7
    },
    [TourDurationFilters.ONE_TWO_WEEKS]: {
      minDays: 8,
      maxDays: 14
    },
    [TourDurationFilters.TWO_FOUR_WEEKS]: {
      minDays: 15,
      maxDays: 28
    },
    [TourDurationFilters.MORE_THAN_FOUR_WEEKS]: {
      minDays: 29,
      maxDays: 9999
    }
  };

  if (durationFilters.includes(SpecialFilters.ALL as any)) {
    return true;
  }

  return durationFilters
    .map(
      (durationFilter: FilterNames) =>
        tour.days >= durationMapper[durationFilter].minDays && tour.days <= durationMapper[durationFilter].maxDays
    )
    .includes(true);
};
