import { Page } from '@model/common/pages';
import React from 'react';
import { ErrorHero } from './ErrorHero';
import { NextRouter, useRouter } from 'next/router';
import Head from 'next/head';
import { useI18NextContext } from '@components/hooks';
import { withIsServer } from '@components/hoc';
import { sentryException } from '@util/sentry';
import { ErrorPageProps } from '@model/common/error-codes';

export const Error404 = withIsServer(({ code, isServer }: ErrorPageProps) => {
  const router: NextRouter = useRouter();
  const handleOnClick = () => router.replace(Page.HOME);
  const t = useI18NextContext();
  const subtitle = t('error__404__code');
  if (!isServer) {
    sentryException(`${subtitle}${code ? `: ${code}` : ''}`);
  }

  return (
    <>
      <Head>
        <title>{`${subtitle} | ${t('common__brand_display')}`}</title>
      </Head>
      <ErrorHero
        title={t('error__404__title')}
        subtitle={subtitle}
        code={code}
        src={'/assets/images/error/error-404.jpg'}
        onClick={handleOnClick}
        buttonLabel={t('error__button-label')}
        paragraph={t('error__404__paragraph')}
      />
    </>
  );
});
