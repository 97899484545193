import { cloneDeep, get, map, merge, set } from 'lodash';
import {
  CMSComponentIdentifiers,
  CMSContentTypes,
  CMSDestination,
  CMSHereToHelp,
  CmsPages,
  TravelGuide
} from '@model/contentful';
import { createClient, Entry, EntryCollection } from 'contentful';
import { TripTypes } from '@model/common/tours/trip-types';
import { EnvConfig } from '@model/config/env-config';
import { ContentfulEnv, ContentfulEnvironment } from '@model/config/contentful';
import { NewsArticle } from '@model/contentful/news-article/news-article';
import { Environment } from '@model/config/environment';
import { Experience } from '@model/contentful/experience/experience';
import { ExperienceLandingPage } from '@model/contentful/experience/experience-landing-page';
import { TourDataReference, TourDataReferenceResponse } from '@model/contentful/tours/tour-data-reference';
import { AppVariant, getPageVariant } from '@model/config/brands';

export const deepPick = (paths, obj) =>
  paths.reduce((returnObj, path) => {
    const pathSteps = path.split('.');
    for (let i = 0; i < pathSteps.length; i++) {
      const steppedPath = pathSteps.slice(0, i + 1).join('.');
      const value = get(obj, steppedPath);
      if (Array.isArray(value)) {
        const restOfPath = pathSteps.slice(i + 1).join('.');
        if (restOfPath) {
          const allowForArrayIndex = restOfPath.split('.');
          const indexStr = allowForArrayIndex[0];
          if (!isNaN(indexStr)) {
            const index = parseInt(indexStr, 10);
            const setArray: Array<any> = [];
            setArray[index] = deepPick([allowForArrayIndex.slice(1).join('.')], value[index]);
            set(returnObj, steppedPath, setArray);
          }
          set(returnObj, steppedPath, merge(get(returnObj, steppedPath) || [], mapPick([restOfPath], value)));
        } else {
          set(returnObj, steppedPath, value);
        }
        return returnObj;
      }
    }
    const value = get(obj, path);
    if (value) {
      set(returnObj, path, value);
    }
    return returnObj;
  }, {});
export const mapPick = (paths, collection) => map(collection, (item) => deepPick(paths, item));

export interface GetPageConfigParams {
  includeLevel?: number;
  pageId: CmsPages;
}

interface ContentfulApiRequests {
  getPageConfig: (params: GetPageConfigParams) => Promise<EntryCollection<any>>;
}

export class ContentfulApi implements ContentfulApiRequests {
  // NOTE: access_token is required because the CloudFront CDN does not pass through the headers
  access_token: string = EnvConfig.get().CONTENTFUL_ACCESS_TOKEN;
  appVariantId: string = EnvConfig.get().APP_VARIANT_ID;
  contentfulEnv: ContentfulEnvironment = ContentfulEnv.getEnv();
  isWhitelabel: boolean = AppVariant.isWhitelabel();

  public async getCMSEntries(params: any): Promise<EntryCollection<any>> {
    const client = createClient({
      accessToken: EnvConfig.get().CONTENTFUL_ACCESS_TOKEN,
      space: EnvConfig.get().CONTENTFUL_SPACE,
      environment: this.contentfulEnv,
      ...(EnvConfig.get().APP_ENV === Environment.LOCAL ? {} : { host: 'cfcdn.mercuryholidays.co.uk' })
    });
    const cmsEntries = await client.getEntries({ ...params, access_token: this.access_token });
    return cmsEntries;
  }

  public async getCMSEntry(id: string, params: any): Promise<Entry<any>> {
    const client = createClient({
      accessToken: EnvConfig.get().CONTENTFUL_ACCESS_TOKEN,
      space: EnvConfig.get().CONTENTFUL_SPACE,
      environment: this.contentfulEnv,
      ...(EnvConfig.get().APP_ENV === Environment.LOCAL ? {} : { host: 'cfcdn.mercuryholidays.co.uk' })
    });
    return await client.getEntry(id, { ...params, access_token: this.access_token });
  }

  public async getPageConfig({ pageId, includeLevel }: GetPageConfigParams): Promise<EntryCollection<any>> {
    const pageConfig = await this.getCMSEntries({
      content_type: CMSContentTypes.PAGE,
      include: includeLevel || 3,
      'fields.pageId': getPageVariant(pageId),
      select:
        'fields.pageId,fields.title,fields.description,fields.shortDescription,fields.metaTitle,fields.metaDescription,fields.components,fields.sidebar,fields.hero'
    });

    const updatedConfig = await this.renderSidebarOverride(pageConfig.items[0]);
    return { ...pageConfig, items: [updatedConfig] };
  }

  public async getTours(): Promise<Array<TourDataReference>> {
    const response: EntryCollection<TourDataReference> = await this.getCMSEntries({
      content_type: CMSContentTypes.TOUR,
      include: 2,
      select: 'fields',
      limit: 999
    });
    return response.items.map((tour: TourDataReferenceResponse) => tour.fields) || [];
  }

  public async getToursByPath(path: string): Promise<Array<TourDataReference>> {
    const tours: Array<TourDataReference> = await this.getTours();
    return tours.filter((tour: TourDataReference) => tour.destination.fields.destinationPath.fields.path === path);
  }

  public async getToursProductPageConfig({ path, includeLevel }: { path: string; includeLevel?: number }) {
    const toursProductPageConfig = await this.getCMSEntries({
      content_type: CMSContentTypes.TOURS_PRODUCT_PAGE,
      include: includeLevel || 2,
      'fields.toursProductPath.fields.path': path,
      'fields.toursProductPath.sys.contentType.sys.id': 'toursProductPath',
      select: 'fields'
    });

    const updatedConfig = await this.renderSidebarOverride(toursProductPageConfig.items[0]);

    return { ...toursProductPageConfig, items: [updatedConfig] };
  }

  public async getCMSResort(path: string, includeLevel?: number): Promise<EntryCollection<any>> {
    return await this.getCMSEntries({
      content_type: CMSContentTypes.RESORT,
      'fields.path': path,
      include: includeLevel || 2,
      select: 'fields'
    });
  }

  public async getCMSDestinations(): Promise<Array<CMSDestination>> {
    const cmsDestinations: EntryCollection<CMSDestination> = await this.getCMSEntries({
      content_type: CMSContentTypes.DESTINATION,
      include: 1,
      select: 'fields'
    });
    const requiredFields = [
      'fields.title',
      'fields.countryIsoCode',
      'fields.flightTime',
      'fields.tourRegionId',
      'fields.destinationPath.fields.path',
      'fields.tripTypes.fields.tripTypeId',
      'fields.tripTypes.fields.title',
      'fields.tripTypes.fields.order',
      'fields.continent.fields.name'
    ];
    const returnDestinations = mapPick(requiredFields, cmsDestinations.items);
    return map(returnDestinations, 'fields');
  }

  public async getCMSDestination(path: string, includeLevel?: number): Promise<EntryCollection<any>> {
    return await this.getCMSEntries({
      content_type: CMSContentTypes.DESTINATION,
      'fields.destinationPath.fields.path': path,
      'fields.destinationPath.sys.contentType.sys.id': 'destinationPath',
      include: includeLevel || 1,
      select: 'fields'
    });
  }

  public async getCMSDestinationTravelGuides(path: string, tripType?: TripTypes): Promise<EntryCollection<any>> {
    const travelGuidesConfig = await this.getCMSEntries({
      content_type: CMSContentTypes.DESTINATION_PAGE,
      'fields.destinationPath.fields.path': path,
      'fields.destinationPath.sys.contentType.sys.id': 'destinationPath',
      'fields.tripType.fields.tripTypeId': tripType || TripTypes.ALL,
      'fields.tripType.sys.contentType.sys.id': 'tripType',
      include: 2,
      select: 'fields.title,fields.destinationPath,fields.travelGuides,fields.destination'
    });

    const pageConfig = travelGuidesConfig.items[0];
    const travelGuides = pageConfig?.fields?.travelGuides || [];

    const requiredFields = [
      'sys.id',
      'fields.path',
      'fields.title',
      'fields.metaTitle',
      'fields.metaDescription',
      'fields.shortDescription',
      'fields.heroImage.fields.file.url',
      'fields.content'
    ];
    const returnTravelGuides = mapPick(requiredFields, travelGuides);

    const updatedConfig = await this.renderSidebarOverride({
      ...pageConfig,
      fields: {
        ...pageConfig?.fields,
        travelGuides: returnTravelGuides
      }
    });

    return { ...travelGuidesConfig, items: [updatedConfig] };
  }

  public async getDestinationTravelGuides(path: string, tripType?: TripTypes): Promise<Array<TravelGuide>> {
    return await this.getCMSEntries({
      content_type: CMSContentTypes.DESTINATION_PAGE,
      'fields.destinationPath.fields.path': path,
      'fields.tripType.fields.tripTypeId': tripType || TripTypes.ALL,
      'fields.tripType.sys.contentType.sys.id': 'tripType',
      'fields.destinationPath.sys.contentType.sys.id': 'destinationPath',
      include: 2,
      select: 'fields.travelGuides'
    }).then((result: any) => {
      const travelGuides = result.items[0]?.fields?.travelGuides;
      if (travelGuides) {
        const requiredFields = [
          'fields.path',
          'fields.title',
          'fields.shortDescription',
          'fields.heroImage.fields.file.url'
        ];
        const returnTravelGuides = mapPick(requiredFields, travelGuides);
        return returnTravelGuides;
      }
      return [];
    });
  }

  public async getSitemapTravelGuides(path: string, tripType?: TripTypes): Promise<any> {
    return await this.getCMSEntries({
      content_type: CMSContentTypes.DESTINATION_PAGE,
      'fields.destinationPath.fields.path': path,
      'fields.tripType.fields.tripTypeId': tripType || TripTypes.ALL,
      'fields.tripType.sys.contentType.sys.id': 'tripType',
      'fields.destinationPath.sys.contentType.sys.id': 'destinationPath',
      include: 2,
      select: 'fields.travelGuides,fields.videoGuides,fields.destinationPath,fields.tripType'
    }).then((result: any) => {
      const travelGuides = result.items[0]?.fields.travelGuides;
      if (travelGuides) {
        const requiredFields = [
          'fields.travelGuides.fields.path',
          'fields.videoGuides.fields.path',
          'fields.destinationPath.fields.path',
          'fields.tripType.fields.tripTypeId'
        ];
        const returnTravelGuides = deepPick(requiredFields, result.items[0]);
        return returnTravelGuides;
      }
      return [];
    });
  }

  public async getCMSDestinationVideoGuides(path: string, tripType?: TripTypes): Promise<EntryCollection<any>> {
    const videoGuidesConfig = await this.getCMSEntries({
      content_type: CMSContentTypes.DESTINATION_PAGE,
      'fields.destinationPath.fields.path': path,
      'fields.destinationPath.sys.contentType.sys.id': 'destinationPath',
      'fields.tripType.fields.tripTypeId': tripType || TripTypes.ALL,
      'fields.tripType.sys.contentType.sys.id': 'tripType',
      include: 2,
      select: 'fields.title,fields.destinationPath,fields.videoGuides,fields.destination'
    });

    const updatedConfig = await this.renderSidebarOverride(videoGuidesConfig.items[0]);

    return { ...videoGuidesConfig, items: [updatedConfig] };
  }

  public async getCMSTourById(tourId: number, definitionId?: number): Promise<EntryCollection<any>> {
    return await this.getCMSEntries({
      content_type: CMSContentTypes.TOUR,
      'fields.tourId': tourId,
      'fields.definitionId': definitionId,
      select: 'fields.tourProductPath',
      include: 1
    });
  }

  public async getTripTypePageConfig({ tripType, includeLevel }: { tripType?: TripTypes; includeLevel?: number }) {
    const tripTypePageConfig = await this.getCMSEntries({
      content_type: CMSContentTypes.TRIP_TYPE_PAGE,
      include: includeLevel || 2,
      ...(tripType && {
        'fields.tripType.fields.tripTypeId': tripType,
        'fields.tripType.sys.contentType.sys.id': 'tripType'
      }),
      select: 'fields'
    });

    return tripTypePageConfig;
  }

  public async getAllTripTypes(): Promise<EntryCollection<any>> {
    return await this.getCMSEntries({
      content_type: CMSContentTypes.TRIP_TYPE,
      include: 0
    });
  }

  public async getAllContinents(): Promise<EntryCollection<any>> {
    return await this.getCMSEntries({
      content_type: CMSContentTypes.CONTINENT,
      include: 1
    });
  }

  public async getPromoBanner(tripType: TripTypes): Promise<EntryCollection<any>> {
    return await this.getCMSEntries({
      content_type: CMSContentTypes.PROMO_BANNER,
      'fields.tripType.fields.tripTypeId': tripType,
      'fields.tripType.sys.contentType.sys.id': 'tripType',
      include: 2
    });
  }

  public async getAgentPage(id: string): Promise<EntryCollection<any>> {
    return await this.getCMSEntries({
      content_type: CMSContentTypes.AGENTS_PAGE,
      'fields.id': id,
      include: 2,
      select: 'fields'
    });
  }

  public async getArticles(): Promise<Array<NewsArticle>> {
    const query: EntryCollection<NewsArticle> = await this.getCMSEntries({
      content_type: CMSContentTypes.AGENTS_ARTICLE,
      include: 1,
      select: 'fields'
    });
    return query?.items?.map((item: Entry<NewsArticle>) => item.fields) || [];
  }

  public async getArticle(path: string): Promise<Array<NewsArticle>> {
    const query: EntryCollection<NewsArticle> = await this.getCMSEntries({
      content_type: CMSContentTypes.AGENTS_ARTICLE,
      include: 1,
      'fields.path': path,
      select: 'fields'
    });
    return query?.items?.map((item: Entry<NewsArticle>) => item.fields) || [];
  }

  public async getPromoBannerByCMSConfigId(cmsConfigId: string): Promise<EntryCollection<any>> {
    return await this.getCMSEntries({
      'sys.id': cmsConfigId,
      include: 2
    });
  }

  public async getExperiencesByDestinationPath(path?: string): Promise<EntryCollection<Experience>> {
    return await this.getCMSEntries({
      content_type: CMSContentTypes.EXPERIENCE,
      ...(path && {
        'fields.destination.fields.path': path
      }),
      'fields.destination.sys.contentType.sys.id': 'destinationPath',
      select: 'fields',
      include: 2
    });
  }

  public async getExperiencesByPath(path: string, destinationPath: string): Promise<EntryCollection<Experience>> {
    return await this.getCMSEntries({
      content_type: CMSContentTypes.EXPERIENCE,
      'fields.path': path,
      'fields.destination.fields.path': destinationPath,
      'fields.destination.sys.contentType.sys.id': 'destinationPath',
      select: 'fields',
      include: 2
    });
  }

  public async getExperienceLandingPage(path: string): Promise<EntryCollection<ExperienceLandingPage>> {
    return await this.getCMSEntries({
      content_type: CMSContentTypes.EXPERIENCE_LANDING_PAGE,
      'fields.destination.fields.path': path,
      'fields.destination.sys.contentType.sys.id': 'destinationPath',
      select: 'fields',
      include: 3
    });
  }

  public async getTravelGuide(sysId: string): Promise<EntryCollection<TravelGuide>> {
    return await this.getCMSEntries({ include: 3, 'sys.id': sysId });
  }

  public async getGlobalConfig(appVariantId: string): Promise<EntryCollection<any>> {
    const globalConfig = await this.getCMSEntries({
      content_type: CMSContentTypes.GLOBAL_CONFIG,
      limit: 1,
      select: 'fields',
      ['fields.appVariantId']: appVariantId
    });
    const requiredFields = [
      'fields.appVariantId',
      'fields.openingTimes.fields',
      'fields.contactDetails.fields',
      'fields.logoDark.fields.file.title',
      'fields.logoDark.fields.file.url',
      'fields.logoLight.fields.file.title',
      'fields.logoLight.fields.file.url'
    ];
    return deepPick(requiredFields, (globalConfig.items || [])[0]);
  }

  public async getHereToHelp(): Promise<CMSHereToHelp> {
    const hereToHelp = await this.getCMSEntries({
      content_type: CMSContentTypes.INFO_TILE_GROUP,
      limit: 1,
      select: 'fields',
      ['fields.title']: CMSComponentIdentifiers.HERE_TO_HELP
    });
    const requiredFields = ['fields.title', 'fields.infoTiles.fields.pageId', 'fields.infoTiles.fields.title'];
    const returnHereToHelp = deepPick(requiredFields, (hereToHelp.items || [])[0]);
    return returnHereToHelp.fields;
  }

  public async getSidebar(): Promise<Entry<any>> {
    const sidebar = await this.getCMSEntries({
      content_type: CMSContentTypes.SIDEBAR,
      limit: 1,
      select: 'fields.title,fields.benefits,sys.contentType',
      'fields.variantId': this.appVariantId
    });
    return sidebar && sidebar.items && sidebar.items[0];
  }

  public async getHelpPageConfig(): Promise<EntryCollection<any>> {
    const pageConfig = await this.getPageConfig({ pageId: CmsPages.HELP_PAGE });
    const requiredFields = [
      'fields.title',
      'fields.description',
      'fields.components.sys.contentType.sys.id',
      'fields.components.fields.title',
      'fields.components.fields.icon',
      'fields.components.fields.shortDescription',
      'fields.components.fields.pageId',
      'fields.components.fields.infoTiles.fields.pageId',
      'fields.components.fields.infoTiles.fields.title',
      'fields.components.fields.infoTiles.fields.icon',
      'fields.components.fields.infoTiles.fields.shortDescription'
    ];

    const updatedConfig = await this.renderSidebarOverride(pageConfig.items[0]);
    const parsedConfig = deepPick(requiredFields, updatedConfig);

    return { ...pageConfig, items: [parsedConfig] };
  }

  public async getInfoPageConfig(): Promise<EntryCollection<any>> {
    const pageConfig = await this.getPageConfig({ pageId: CmsPages.HELP_PAGE });
    const requiredFields = ['fields.title', 'fields.metaDescription'];

    const updatedConfig = await this.renderSidebarOverride(pageConfig.items[0]);
    const parsedConfig = deepPick(requiredFields, updatedConfig);

    return { ...pageConfig, items: [parsedConfig] };
  }

  private async renderSidebarOverride(configItem: Entry<any>): Promise<Entry<any>> {
    const returnConfig: Entry<any> = cloneDeep(configItem);
    const hasSidebar: boolean = !!returnConfig?.fields?.sidebar;

    const sidebar = this.isWhitelabel && hasSidebar ? await this.getSidebar() : null;
    if (sidebar) {
      returnConfig.fields.sidebar = [sidebar];
    }
    return returnConfig;
  }
}
