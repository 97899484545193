export enum TripTypes {
  ALL = 'all-trip-types',
  HOLIDAYS = 'holidays',
  ESCORTED_TOURS = 'escorted-tours',
  RIVER_CRUISES = 'river-cruises',
  FLY_DRIVES = 'fly-drives',
  WALKING_ROUTES = 'walking-routes',
  RAIL_JOURNEYS = 'rail-journeys',
  MULTI_CENTRE = 'multicentres',
  PRIVATE_TOURS = 'private-tours',
  SOLO_TOURS = 'solo-tours',
  CRUISES = 'cruises',
  ADD_ONS = 'add-ons'
}

export const TripSearchTypes = {
  [TripTypes.ESCORTED_TOURS]: 'ESCORTED_TOURS',
  [TripTypes.RIVER_CRUISES]: 'RIVER_CRUISES',
  [TripTypes.FLY_DRIVES]: 'FLY_DRIVES',
  [TripTypes.WALKING_ROUTES]: 'WALKING_ROUTES',
  [TripTypes.RAIL_JOURNEYS]: 'RAIL_JOURNEYS',
  [TripTypes.MULTI_CENTRE]: 'MULTI_CENTRE',
  [TripTypes.PRIVATE_TOURS]: 'PRIVATE_TOURS',
  [TripTypes.SOLO_TOURS]: 'SOLO_TOURS',
  [TripTypes.CRUISES]: 'CRUISES'
};

// TODO: get these from locales
export const tripTypeLabels = {
  [TripTypes.ALL]: 'All',
  [TripTypes.HOLIDAYS]: 'Holidays',
  [TripTypes.ESCORTED_TOURS]: 'Escorted Tours',
  [TripTypes.RIVER_CRUISES]: 'River Cruises',
  [TripTypes.FLY_DRIVES]: 'Fly Drives',
  [TripTypes.WALKING_ROUTES]: 'Walking Routes',
  [TripTypes.RAIL_JOURNEYS]: 'Rail Journeys',
  [TripTypes.MULTI_CENTRE]: 'Multi-Centres',
  [TripTypes.PRIVATE_TOURS]: 'Private Tours',
  [TripTypes.SOLO_TOURS]: 'Solo Tours',
  [TripTypes.CRUISES]: 'Cruises',
  [TripTypes.ADD_ONS]: 'Add Ons'
};

export const tourTypes: Array<TripTypes> = [
  TripTypes.ESCORTED_TOURS,
  TripTypes.RIVER_CRUISES,
  TripTypes.FLY_DRIVES,
  TripTypes.WALKING_ROUTES,
  TripTypes.RAIL_JOURNEYS,
  TripTypes.MULTI_CENTRE,
  TripTypes.PRIVATE_TOURS,
  TripTypes.SOLO_TOURS,
  TripTypes.CRUISES
];

export const TripTypeKeys = {
  [TripTypes.HOLIDAYS]: 'trip-type-key--holiday',
  [TripTypes.ESCORTED_TOURS]: 'trip-type-key--tour',
  [TripTypes.RIVER_CRUISES]: 'trip-type-key--river-cruise',
  [TripTypes.FLY_DRIVES]: 'trip-type-key--fly-drive-holiday',
  [TripTypes.WALKING_ROUTES]: 'trip-type-key--walking-tour',
  [TripTypes.RAIL_JOURNEYS]: 'trip-type-key--rail-journey',
  [TripTypes.MULTI_CENTRE]: 'trip-type-key--multi-centre',
  [TripTypes.SOLO_TOURS]: 'trip-type-key--solo-tours',
  [TripTypes.CRUISES]: 'trip-type-key--cruise',
  [TripTypes.ADD_ONS]: 'trip-type-key--add-on'
};

export const TripTypeFromPrice = {
  [TripTypes.HOLIDAYS]: 'fromPriceHoliday',
  [TripTypes.ESCORTED_TOURS]: 'fromPriceEscortedTour',
  [TripTypes.RIVER_CRUISES]: 'fromPriceRiverCruise',
  [TripTypes.MULTI_CENTRE]: 'fromPriceMultiCentre',
  [TripTypes.WALKING_ROUTES]: 'fromPriceWalkingTour',
  [TripTypes.RAIL_JOURNEYS]: 'fromPriceRailJourney',
  [TripTypes.PRIVATE_TOURS]: 'fromPricePrivateTour',
  [TripTypes.SOLO_TOURS]: 'fromPriceSoloTour',
  [TripTypes.CRUISES]: 'fromPriceCruise',
  [TripTypes.FLY_DRIVES]: 'fromPriceFlyDrive'
};
