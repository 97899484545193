import { BaseAction } from '@model/redux';
import { isMobile, isDesktop } from '@styles/breakpoints';

export enum UiStateActions {
  SET_IS_SERVER = '@APP/SET_IS_SERVER',
  SET_IS_MOBILE = '@APP/SET_IS_MOBILE',
  SET_IS_DESKTOP = '@APP/SET_IS_DESKTOP',
  SET_SHOW_SEARCH = '@APP/SET_SHOW_SEARCH'
}

export interface SetIsServerType extends BaseAction {
  type: UiStateActions.SET_IS_SERVER;
  payload: boolean;
}

export const setIsServer: (payload: boolean) => SetIsServerType = (payload: boolean) => ({
  type: UiStateActions.SET_IS_SERVER,
  payload
});

export interface SetIsMobileType extends BaseAction {
  type: UiStateActions.SET_IS_MOBILE;
  payload: boolean;
}

export const setIsMobile: (payload: boolean) => SetIsMobileType = (payload: boolean) => ({
  type: UiStateActions.SET_IS_MOBILE,
  payload
});

export interface SetIsDesktopType extends BaseAction {
  type: UiStateActions.SET_IS_DESKTOP;
  payload: boolean;
}

export const setIsDesktop: (payload: boolean) => SetIsDesktopType = (payload: boolean) => ({
  type: UiStateActions.SET_IS_DESKTOP,
  payload
});

export interface SetShowSearchType extends BaseAction {
  type: UiStateActions.SET_SHOW_SEARCH;
  payload: boolean;
}

export const setShowSearch: (payload: boolean) => SetShowSearchType = (payload: boolean) => ({
  type: UiStateActions.SET_SHOW_SEARCH,
  payload
});

export interface UiState {
  isServer: boolean;
  isMobile: boolean;
  isDesktop: boolean;
  showSearch: boolean;
}

export const INITIAL_UI_STATE: UiState = {
  isServer: typeof window === 'undefined',
  isMobile: isMobile(),
  isDesktop: isDesktop(),
  showSearch: false
};

export const uiStateReducer = (state: UiState = INITIAL_UI_STATE, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case UiStateActions.SET_IS_SERVER:
      return { ...state, isServer: payload };
    case UiStateActions.SET_IS_MOBILE:
      return { ...state, isMobile: payload };
    case UiStateActions.SET_IS_DESKTOP:
      return { ...state, isDesktop: payload };
    case UiStateActions.SET_SHOW_SEARCH:
      return { ...state, showSearch: payload };
    default:
      return state;
  }
};
