import {
  TourAvailabilityList,
  TourContent,
  TourDetailResponse,
  TourResponse,
  TripTypeToursResponse,
  TourDossier
} from '@model/tours';
import { EndPoint, Request, getURL } from '@model/service';
import uri from 'urijs';
import { ItineraryResponse } from '@model/iceberg/common';
import { TourTestimonial } from '@model/iceberg/testimonial';
import { TripTypes } from '@model/contentful';
import { Logging } from '@util/logging';

export interface TourAvailabilityParams {
  path: string;
  tripType: TripTypes;
  searchToken?: string;
  adults?: string;
  airport?: string;
  authToken?: string;
}

export interface TourDossiersParams {
  path: string;
  authToken?: string;
  privateTour?: boolean;
}

interface TourTestimonialsParams {
  path: string;
  tripType?: TripTypes;
  authToken?: string;
  limit?: number;
}

type TourContentOrError = TourResponse<Array<TourContent>> | Error;

interface ToursApiRequests {
  getTourAvailability: (params: TourAvailabilityParams) => Promise<TourResponse<TourAvailabilityList>>;
  getTourContent: (path: string, authToken?: string) => Promise<TourContentOrError>;
  getTourDetails: (path: string, authToken?: string) => Promise<TourResponse<TourDetailResponse>>;
  getTourItinerary: (path: string, authToken?: string) => Promise<TourResponse<ItineraryResponse>>;
  getTripTypeTours: (tripType: TripTypes) => Promise<TripTypeToursResponse>;
  getTourDossiers: (params: TourDossiersParams) => Promise<TourResponse<Array<TourDossier>>>;
  getTourTestimonials: (params: TourTestimonialsParams) => Promise<Array<TourTestimonial>>;
}

export class ToursApi implements ToursApiRequests {
  private api: Request = new Request();

  private handleError = (path: string) => (e: any) => {
    const { config, response } = e;
    Logging.warn({
      statusCode: response?.status,
      url: config?.url,
      text: response?.statusText,
      data: path
    });
    return e;
  };

  public async getTourAvailability(params: TourAvailabilityParams): Promise<TourResponse<TourAvailabilityList>> {
    const { path, searchToken, adults, airport, authToken, tripType } = params;
    return await this.api
      .get(
        uri(getURL()).path(EndPoint.TOUR_AVAILABILITY).search({ path, searchToken, adults, airport, tripType }).href(),
        authToken
      )
      .catch(this.handleError(path));
  }

  public async getTourContent(
    path: string,
    authToken?: string,
    privateTour?: boolean
  ): Promise<TourResponse<Array<TourContent>>> {
    return await this.api
      .get(uri(getURL()).path(EndPoint.TOUR_CONTENT).search({ path, private: privateTour }).href(), authToken)
      .catch(this.handleError(path));
  }

  public async getTourDetails(
    path: string,
    authToken?: string,
    privateTour?: boolean
  ): Promise<TourResponse<TourDetailResponse>> {
    return await this.api
      .get(
        uri(getURL())
          .path(EndPoint.TOUR_DETAIL)
          .search({ path: `${path}${privateTour ? '-private' : ''}` })
          .href(),
        authToken
      )
      .catch(this.handleError(path));
  }

  public async getTourItinerary(path: string, authToken?: string): Promise<TourResponse<ItineraryResponse>> {
    return await this.api
      .get(uri(getURL()).path(EndPoint.TOUR_ITINERARY).search({ path }).href(), authToken)
      .catch(this.handleError(path));
  }

  public async getTripTypeTours(tripType: TripTypes): Promise<TripTypeToursResponse> {
    return await this.api
      .get(uri(getURL()).path(EndPoint.TRIP_TYPE_TOURS).search({ tripType }).href())
      .catch((error) => {
        Logging.error({ text: `getTripTypeTours failed: ${error}` });
      });
  }

  public async getTourDossiers(params: TourDossiersParams): Promise<TourResponse<Array<TourDossier>>> {
    const { path, authToken, privateTour } = params;
    return await this.api
      .get(uri(getURL()).path(EndPoint.TOUR_DOCUMENTS).search({ path, private: privateTour }).href(), authToken)
      .catch(this.handleError(path));
  }

  public async getTourTestimonials(params: TourTestimonialsParams): Promise<Array<TourTestimonial>> {
    const { path, tripType, limit = 4, authToken } = params;
    return await this.api
      .get(uri(getURL()).path(EndPoint.TOUR_TESTIMONIALS).search({ path, tripType, limit }).href(), authToken)
      .catch(this.handleError(path));
  }
}
