import {
  MyBookingDetails,
  MyBookingToken,
  MyBookingResponse,
  MyBookingErrorDetails,
  MyBookingDetailsPending
} from '@model/mmb';
import { MyBookingLoginFormPayload } from '@model/forms';
import { HYDRATE } from 'next-redux-wrapper';
import { isEqual } from 'lodash';
import { takeLatest, put, select, call } from 'redux-saga/effects';
import { Page } from '@model/common';
import { MyBookingApi } from '@model/iceberg/service/mmb/my-booking-api';
import { log } from '@util/logging';
import { LogType } from '@model/common/logging';
import { BaseAction } from '@model/redux';
import { performNavigate, performRedirect } from '@state/app';
import { getBookingToken } from './mmbSelectors';
import { AgentActions } from '@state/agent/agentOperations';
import { resetPaymentForm } from '@state/payment/paymentFormOperations';

/* ***************** *
 *       TYPES       *
 * ***************** */

export interface MyBookingState {
  bookingDetails: MyBookingDetails;
  loading: boolean;
  error: boolean;
  locked: boolean;
  agent: boolean;
  token: MyBookingToken;
  bookingId: string;
  bookingError?: MyBookingErrorDetails;
  pending: boolean;
}

export interface MyBookingAction {
  type: MyBookingActions;
  payload?: any;
}

export interface MyBookingDetailsRequestPayload {
  loginToken: string;
  bookingId: number;
}

export interface MyBookingDetailsPollPayload extends MyBookingDetailsRequestPayload {
  retryIf: (bookingDetails: any) => boolean;
}

export interface MyBookingLogoutPayload {
  bookingId: number;
}

export interface MyBookingPaymentPayload {
  cardholderName: string;
  postCode: string;
  amount: string;
}

/* ***************** *
 *   ACTION TYPES    *
 * ***************** */

export enum MyBookingActions {
  REQUEST_BOOKING_TOKEN = '@MMB/REQUEST_BOOKING_TOKEN',
  REQUEST_BOOKING_TOKEN_SUCCESS = '@MMB/REQUEST_BOOKING_TOKEN_SUCCESS',
  BOOKING_LOCKED = '@MMB/BOOKING_LOCKED',
  AGENT_BOOKING = '@MMB/AGENT_BOOKING',
  REQUEST_BOOKING_LOGOUT = '@MMB/REQUEST_BOOKING_LOGOUT',
  REQUEST_BOOKING_DETAILS = '@MMB/REQUEST_BOOKING_DETAILS',
  POLL_BOOKING_DETAILS = '@MMB/POLL_BOOKING_DETAILS',
  MY_BOOKING_ERROR = '@MMB/MY_BOOKING_ERROR',
  MY_BOOKING_PENDING = '@MMB/MY_BOOKING_PENDING',
  REQUEST_BOOKING_DETAILS_SUCCESS = '@MMB/REQUEST_BOOKING_DETAILS_SUCCESS',
  RESET_BOOKING_DETAILS = '@MMB/RESET_BOOKING_DETAILS',
  RESET_BOOKING_PENDING = '@MMB/RESET_BOOKING_PENDING',
  RESET_BOOKING_ERROR = '@MMB/RESET_BOOKING_ERROR',
  SET_BOOKING_ID = '@MMB/SET_BOOKING_ID'
}

/* ***************** *
 *     ACTIONS       *
 * ***************** */

export interface RequestBookingToken extends BaseAction {
  type: MyBookingActions.REQUEST_BOOKING_TOKEN;
  payload: MyBookingLoginFormPayload;
}

export const requestBookingToken: (payload: MyBookingLoginFormPayload) => RequestBookingToken = (
  payload: MyBookingLoginFormPayload
) => {
  return {
    type: MyBookingActions.REQUEST_BOOKING_TOKEN,
    payload
  };
};

export interface SetBookingId extends BaseAction {
  type: MyBookingActions.SET_BOOKING_ID;
  payload: string;
}

export const setBookingID: (payload: string) => SetBookingId = (payload: string) => {
  return {
    type: MyBookingActions.SET_BOOKING_ID,
    payload
  };
};

export interface RequestBookingTokenSuccess extends BaseAction {
  type: MyBookingActions.REQUEST_BOOKING_TOKEN_SUCCESS;
  payload: MyBookingToken;
}

export const requestBookingTokenSuccess: (payload: MyBookingToken) => RequestBookingTokenSuccess = (
  payload: MyBookingToken
) => {
  return {
    type: MyBookingActions.REQUEST_BOOKING_TOKEN_SUCCESS,
    payload
  };
};

export interface RequestBookingDetails extends BaseAction {
  type: MyBookingActions.REQUEST_BOOKING_DETAILS;
  payload: MyBookingDetailsRequestPayload;
}

export const requestBookingDetails: (payload: MyBookingDetailsRequestPayload) => RequestBookingDetails = (
  payload: MyBookingDetailsRequestPayload
) => {
  return {
    type: MyBookingActions.REQUEST_BOOKING_DETAILS,
    payload
  };
};

export interface PollBookingDetails extends BaseAction {
  type: MyBookingActions.POLL_BOOKING_DETAILS;
  payload: MyBookingDetailsRequestPayload;
}

export const updateBookingDetails: (payload: MyBookingDetailsPollPayload) => PollBookingDetails = (
  payload: MyBookingDetailsPollPayload
) => {
  return {
    type: MyBookingActions.POLL_BOOKING_DETAILS,
    payload
  };
};

export interface RequestBookingDetailsSuccess extends BaseAction {
  type: MyBookingActions.REQUEST_BOOKING_DETAILS_SUCCESS;
  payload: MyBookingDetails;
}

export const requestBookingDetailsSuccess: (payload: MyBookingDetails) => RequestBookingDetailsSuccess = (
  payload: MyBookingDetails
) => {
  return {
    type: MyBookingActions.REQUEST_BOOKING_DETAILS_SUCCESS,
    payload
  };
};

export interface MyBookingPending {
  type: MyBookingActions.MY_BOOKING_PENDING;
  payload?: MyBookingDetailsPending;
}

export const myBookingPending: (payload?: MyBookingDetailsPending) => MyBookingPending = (
  payload?: MyBookingDetailsPending
) => {
  return {
    type: MyBookingActions.MY_BOOKING_PENDING,
    payload
  };
};

export interface MyBookingError {
  type: MyBookingActions.MY_BOOKING_ERROR;
  payload?: MyBookingErrorDetails;
}

export const myBookingError: (payload?: MyBookingErrorDetails) => MyBookingError = (
  payload?: MyBookingErrorDetails
) => {
  return {
    type: MyBookingActions.MY_BOOKING_ERROR,
    payload
  };
};

export interface ResetBookingDetails extends BaseAction {
  type: MyBookingActions.RESET_BOOKING_DETAILS;
}

export const resetBookingDetails: () => ResetBookingDetails = () => {
  return {
    type: MyBookingActions.RESET_BOOKING_DETAILS
  };
};

export interface ResetBookingPending extends BaseAction {
  type: MyBookingActions.RESET_BOOKING_PENDING;
}

export const resetBookingPending: () => ResetBookingPending = () => {
  return {
    type: MyBookingActions.RESET_BOOKING_PENDING
  };
};

export interface ResetBookingError extends BaseAction {
  type: MyBookingActions.RESET_BOOKING_ERROR;
}

export const resetBookingError: () => ResetBookingError = () => {
  return {
    type: MyBookingActions.RESET_BOOKING_ERROR
  };
};

export interface RequestBookingLogout extends BaseAction {
  type: MyBookingActions.REQUEST_BOOKING_LOGOUT;
  payload: MyBookingLogoutPayload;
}

export const requestBookingLogout: (bookingId: number) => RequestBookingLogout = (bookingId: number) => {
  return {
    type: MyBookingActions.REQUEST_BOOKING_LOGOUT,
    payload: { bookingId }
  };
};

export interface BookingLocked {
  type: MyBookingActions.BOOKING_LOCKED;
}

export const bookingLocked: () => BookingLocked = () => {
  return {
    type: MyBookingActions.BOOKING_LOCKED
  };
};

export interface AgentBooking {
  type: MyBookingActions.AGENT_BOOKING;
}

export const agentBooking: () => AgentBooking = () => {
  return {
    type: MyBookingActions.AGENT_BOOKING
  };
};

/* ********** *
 *   SAGAS    *
 * ********** */

export function* onRequestBookingToken() {
  yield takeLatest(MyBookingActions.REQUEST_BOOKING_TOKEN, fetchBookingToken);
}

export function* onRequestBookingDetails() {
  yield takeLatest(MyBookingActions.REQUEST_BOOKING_DETAILS, fetchBookingDetails);
}

export function* onPollBookingDetails() {
  yield takeLatest(MyBookingActions.POLL_BOOKING_DETAILS, pollBookingDetails);
}

export function* onRequestBookingLogout() {
  yield takeLatest(MyBookingActions.REQUEST_BOOKING_LOGOUT, bookingLogout);
}

export function* fetchBookingToken({ payload }: any) {
  const { bookingToken, error } = yield call(performFetchBookingToken, payload);

  if (bookingToken) {
    yield put(requestBookingTokenSuccess(bookingToken.data));
    yield put(performRedirect(Page.MY_BOOKING_OVERVIEW));
    yield put(resetPaymentForm());
  }

  if (error) {
    yield call(log, {
      statusCode: error.response.status,
      text: `Error fetching MMB Booking Token: ${error.errorText}`,
      logType: LogType.ERROR
    });
    if (error.response.status === 429) {
      yield put(bookingLocked());
    } else if (error.response.status === 403) {
      yield put(agentBooking());
    } else {
      yield put(myBookingError());
    }
  }
}

export function performFetchBookingToken(payload: MyBookingLoginFormPayload) {
  const api: MyBookingApi = new MyBookingApi();
  return api
    .getMyBookingToken(payload)
    .then((bookingToken: MyBookingResponse<MyBookingToken>) => ({
      bookingToken
    }))
    .catch((error: any) => ({
      error
    }));
}

export function* fetchBookingDetails({ payload }: any) {
  const { bookingDetails, error } = yield call(performFetchBookingDetails, payload);

  if (bookingDetails) {
    yield put(requestBookingDetailsSuccess(bookingDetails.data));
  }

  if (error) {
    if (error?.errorCode !== 202) {
      const {
        response: { status, data }
      } = error;

      yield call(log, {
        statusCode: error.response.status,
        text: `Error fetching MMB Booking Details: ${error.errorText || error.toString()}`,
        logType: LogType.ERROR
      });
      yield put(myBookingError({ status, data }));
    }

    yield call(log, {
      statusCode: error?.errorCode,
      text: `Error fetching MMB Booking Details: ${error.errorText}`,
      logType: LogType.ERROR
    });

    const { errorCode, errorText } = error;
    yield put(myBookingPending({ errorCode, errorText }));
  }
}

export function performFetchBookingDetails(payload: MyBookingDetailsRequestPayload) {
  const api: MyBookingApi = new MyBookingApi();
  return api
    .getMyBookingDetails(payload)
    .then((bookingDetails: MyBookingResponse<MyBookingDetails>) => ({
      bookingDetails
    }))
    .catch((error: any) => ({
      error
    }));
}

export function* pollBookingDetails({ payload }: any) {
  const { bookingDetails } = yield call(performPollBookingDetails, payload);

  if (bookingDetails) {
    yield put(requestBookingDetailsSuccess(bookingDetails.data));
  }
}

export function performPollBookingDetails(payload: MyBookingDetailsPollPayload) {
  const api: MyBookingApi = new MyBookingApi();
  return api
    .pollMyBookingDetails(payload)
    .then((bookingDetails: MyBookingResponse<MyBookingDetails>) => ({
      bookingDetails
    }))
    .catch((error: any) => ({
      error
    }));
}

export function* bookingLogout({ payload }: any) {
  const bookingToken: MyBookingToken = yield select(getBookingToken);
  const { logout, error } = yield call(performBookingLogout, payload.bookingId, bookingToken.loginToken);

  if (logout?.loggedOut) {
    yield put(resetBookingDetails());
    yield put(performNavigate(Page.MY_BOOKING));
    yield put(resetPaymentForm());
  }

  if (error) {
    yield call(log, {
      statusCode: error.response.status,
      text: `Error invalidating MBB token: ${error.errorText}`,
      logType: LogType.ERROR
    });
  }
}

export function performBookingLogout(bookingId: number, bookingToken: string) {
  const api: MyBookingApi = new MyBookingApi();
  return api
    .invalidateBookingLogin(bookingId, bookingToken)
    .then((logout: any) => ({
      logout
    }))
    .catch((error: any) => ({
      error
    }));
}

/* ***************** *
 *     REDUCER       *
 * ***************** */

export const MMB_INITIAL_STATE: MyBookingState = {
  bookingDetails: {} as any,
  loading: false,
  error: false,
  pending: false,
  locked: false,
  agent: false,
  token: {} as any,
  bookingId: ''
};

export const mmbReducer = (state: MyBookingState = MMB_INITIAL_STATE, action: any) => {
  switch (action.type) {
    case HYDRATE as any:
      if (isEqual(state, MMB_INITIAL_STATE)) {
        return action.payload?.mmb || state;
      }
      return state;
    case MyBookingActions.REQUEST_BOOKING_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload,
        loading: false
      };
    case MyBookingActions.REQUEST_BOOKING_DETAILS:
    case MyBookingActions.REQUEST_BOOKING_TOKEN:
      return {
        ...state,
        bookingDetails: {},
        loading: true,
        error: false,
        pending: false
      };
    case MyBookingActions.REQUEST_BOOKING_DETAILS_SUCCESS:
      return {
        ...state,
        bookingDetails: action.payload,
        loading: false,
        error: false,
        pending: false
      };
    case MyBookingActions.MY_BOOKING_ERROR:
      return {
        ...MMB_INITIAL_STATE,
        error: true,
        pending: false,
        bookingError: action.payload
      };
    case MyBookingActions.MY_BOOKING_PENDING:
      return {
        ...MMB_INITIAL_STATE,
        error: false,
        pending: true,
        bookingPendingError: action.payload
      };
    case MyBookingActions.BOOKING_LOCKED:
      return {
        ...MMB_INITIAL_STATE,
        locked: true
      };
    case MyBookingActions.AGENT_BOOKING:
      return {
        ...MMB_INITIAL_STATE,
        agent: true
      };
    case MyBookingActions.RESET_BOOKING_DETAILS:
      return {
        ...MMB_INITIAL_STATE,
        ...(state.bookingError && { bookingError: state.bookingError }),
        ...(state.pending && { pending: state.pending })
      };
    case MyBookingActions.RESET_BOOKING_PENDING:
      return {
        ...MMB_INITIAL_STATE,
        ...(state.pending && { pending: false })
      };
    case MyBookingActions.RESET_BOOKING_ERROR:
      return {
        ...state,
        bookingError: undefined
      };
    case AgentActions.PERFORM_LOGOUT_AGENT:
      return MMB_INITIAL_STATE;
    case MyBookingActions.SET_BOOKING_ID:
      return {
        ...state,
        bookingId: action.payload
      };
    default:
      return state;
  }
};
