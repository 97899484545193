import styled from '@emotion/styled';
import { mq } from '@styles/breakpoints';
import { textShadowMixins } from '@styles/mixins';
import React from 'react';
import { BaseHero, ImageContainer } from '@components/common/hero/base/BaseHero';
import { Button, ButtonSize } from '@components/material-ui';
import { LogoContainer } from '@components/common/logo';
import { useI18NextContext } from '@components/hooks';
import { getHTML } from '@util/common';
import { ThemeProps } from '@theme/base';

const LandingContainer = styled.div(({ theme }: ThemeProps) => ({
  padding: theme.custom.spacing.medium,
  width: '100%',
  maxWidth: theme.custom.contentSizes.maxContentWidth,
  display: 'flex',
  justifyContent: 'flex-start',
  flexGrow: 1,
  margin: '0 auto',
  height: '100%',
  [mq.medium]: {
    padding: `0 ${theme.custom.spacing.medium}`
  }
}));

const Title = styled.h2(({ theme }: ThemeProps) => ({
  width: '100%',
  ...(theme.custom.typography.h2 as any),
  textShadow: textShadowMixins(theme.custom.colors.black).heavy,
  color: theme.custom.colors.white,
  marginBottom: theme.custom.spacing.large,
  marginTop: 0,
  [mq.medium]: {
    fontSize: theme.custom.fontSize.xLarge
  }
}));

const Subtitle = styled.h4(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h4 as any),
  color: theme.custom.colors.white,
  textShadow: textShadowMixins(theme.custom.colors.black).heavy,
  fontSize: theme.custom.fontSize.medium,
  margin: 0,
  [mq.medium]: {
    fontSize: theme.custom.fontSize.large
  }
}));

const Code = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.small as any),
  marginBottom: theme.custom.spacing.large,
  textTransform: 'uppercase',
  color: theme.custom.colors.white
}));

const Paragraph = styled.p(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  color: theme.custom.colors.white,
  textShadow: textShadowMixins(theme.custom.colors.black).default,
  marginBottom: theme.custom.spacing.xLarge,
  width: '100%'
}));

const MainContainer = styled.div({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'flex-start',
  flexDirection: 'column',
  maxWidth: 650,
  width: '100%'
});

const Container = styled.div(({ theme }: ThemeProps) => ({
  background: 'rgba(2, 37, 49, 0.8)',
  padding: '32px',
  borderRadius: '10px',
  backdropFilter: 'blur(10px)',
  marginTop: theme.custom.spacing.medium,
  [mq.medium]: {
    marginTop: 0
  }
}));

const BackToHomeButton: any = styled(Button)(() => ({
  width: '100%',
  background: 'rgb(0, 86, 114)',
  borderRadius: '6px',
  [mq.small]: {
    width: 250
  }
}));

export const StyledHero = styled(BaseHero)({
  [ImageContainer as any]: {
    height: '100vh',
    position: 'absolute'
  }
});

export interface ErrorHeroProps {
  title: string;
  subtitle: string;
  code?: string;
  buttonLabel: string;
  paragraph: string;
  onClick?: () => void;
  src: string;
}

export const Nav = styled.div(({ theme }: ThemeProps) => ({
  padding: theme.custom.spacing.medium,
  maxWidth: theme.custom.contentSizes.maxContentWidth,
  margin: 'auto'
}));

export const ErrorHero = ({ title, subtitle, code, paragraph, onClick, src, buttonLabel }: ErrorHeroProps) => {
  const t: any = useI18NextContext();

  return (
    <StyledHero
      src={src}
      renderTop={
        <Nav>
          <LogoContainer />
        </Nav>
      }
      renderMain={
        <LandingContainer>
          <MainContainer>
            <Container>
              <Title>{title}</Title>
              <Subtitle>{subtitle}</Subtitle>
              {code && <Code>{t('error-modal__code--label', { code })}</Code>}
              <Paragraph dangerouslySetInnerHTML={getHTML(paragraph)} />
              {onClick && buttonLabel && (
                <BackToHomeButton label={buttonLabel} onClick={onClick} size={ButtonSize.SMALL} />
              )}
            </Container>
          </MainContainer>
        </LandingContainer>
      }
    />
  );
};
