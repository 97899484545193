import { isEqual } from 'lodash';
import { FormikValues } from 'formik';
import { Validation } from '@model/forms/validation/validation';

export const validatePrivateTourEnquiryForm = (values: FormikValues) => {
  const errors: any = {};
  const validFormErrors = {};

  if (!values.departureDate) {
    errors.departureDate = 'form__field-error--required';
  }

  if (!values.name) {
    errors.name = 'form__field-error--required';
  }

  if (!values.email) {
    errors.email = 'form__field-error--required';
  } else if (!Validation.isEmailValid(values.email)) {
    errors.email = 'form__field-error--email';
  }

  if (!values.telephone) {
    errors.telephone = 'form__field-error--required';
  } else {
    const validation = Validation.isTelephoneValidDetails(values.telephone);
    if (!validation.lengthValid) {
      errors.telephone = 'form__field-error--telephone-length';
    } else if (!validation.formatValid) {
      errors.telephone = 'form__field-error--telephone';
    }
  }

  if (!values.comments) {
    errors.comments = 'form__field-error--required';
  }

  const isValid = isEqual(validFormErrors, errors);
  return { errors, isValid };
};
