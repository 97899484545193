import { call, put, takeEvery, throttle, putResolve } from 'redux-saga/effects';
import { compact, uniqBy, flatten, map } from 'lodash';
import {
  AlgoliaApi,
  AlgoliaFilter,
  AlgoliaIndex,
  BrowseConfig,
  SearchConfig
} from '@model/iceberg/service/algolia/AlgoliaApi';
import { AlgoliaState, AlgoliaAirportCacheTypes } from '@model/state/algolia-state';
import {
  AirportResult,
  BooleanTagBuilder,
  BooleanTagType,
  GeographyResult,
  HelpArticleResult,
  ToursByTypeProps,
  TAB_INDEXES
} from '@model/search';
import { TripTypes } from '@model/common/tours/trip-types';
import { select } from '@redux-saga/core/effects';
import { getDealFinderAirports } from '@state/deal-finder/dealFinderSelectors';
import { getAllCMSDestinationsSelector } from '@state/cms';
import {
  getAlgoliaCacheAirportsByType,
  getAlgoliaCacheDestinations,
  getAlgoliaCacheToursByType
} from '@state/algolia/algoliaSelectors';
import { CommonSearchActions } from '@state/search/common/commonSearchOperations';
import { getToursStateNameByType, isTourTripType } from '@util/tours';
import { findCmsDestinationByPath } from '@util/cms';

export enum AlgoliaActions {
  SET_GEOGRAPHY_QUERY = '@ALGOLIA/SET_GEOGRAPHY_QUERY',
  SET_GEOGRAPHY = '@ALGOLIA/SET_GEOGRAPHY',
  SET_AIRPORTS_QUERY = '@ALGOLIA/SET_AIRPORTS_QUERY',
  SET_AIRPORTS = '@ALGOLIA/SET_AIRPORTS',
  SET_DESTINATIONS = '@ALGOLIA/SET_DESTINATIONS',
  FETCH_DESTINATIONS = '@ALGOLIA/FETCH_DESTINATIONS',
  SET_HELP_ARTICLES = '@ALGOLIA/SET_HELP_ARTICLES',
  SET_HELP_ARTICLES_QUERY = '@ALGOLIA/SET_HELP_ARTICLES_QUERY',
  FETCH_TOURS_BY_TYPE = '@ALGOLIA/FETCH_TOURS_BY_TYPE',
  RECEIVE_TOURS_BY_TYPE_SUCCESS = '@ALGOLIA/RECEIVE_TOURS_BY_TYPE_SUCCESS',
  RECEIVE_TOURS_BY_TYPE_FAILURE = '@ALGOLIA/RECEIVE_TOURS_BY_TYPE_FAILURE',
  SET_TOURS_QUERY_BY_TYPE = '@ALGOLIA/SET_TOURS_QUERY_BY_TYPE',
  SET_TOURS_BY_TYPE = '@ALGOLIA/SET_TOURS_BY_TYPE'
}

export const INITIAL_ALGOLIA_STATE: AlgoliaState = {
  geography: {
    data: [],
    query: '',
    error: {},
    loading: false
  },
  airports: {
    data: {
      holidays: [],
      tours: []
    },
    query: '',
    error: {},
    loading: false
  },
  helpArticles: {
    data: [],
    query: '',
    error: {},
    loading: false
  },
  escortedTours: {
    data: [],
    airports: [],
    query: '',
    error: {},
    loading: false
  },
  privateTours: {
    data: [],
    airports: [],
    query: '',
    error: {},
    loading: false
  },
  riverCruises: {
    data: [],
    airports: [],
    query: '',
    error: {},
    loading: false
  },
  multiCentres: {
    data: [],
    airports: [],
    query: '',
    error: {},
    loading: false
  },
  cruises: {
    data: [],
    airports: [],
    query: '',
    error: {},
    loading: false
  },
  cache: {
    airports: null,
    destinations: null,
    escortedTours: null,
    privateTours: null,
    riverCruises: null,
    cruises: null,
    multiCentres: null
  }
};

export const getAlgoliaIndexByType = (tripType: TripTypes) => {
  switch (tripType) {
    case TripTypes.PRIVATE_TOURS:
      return AlgoliaIndex.PRIVATE_TOURS;
    case TripTypes.RIVER_CRUISES:
      return AlgoliaIndex.RIVER_CRUISES;
    case TripTypes.MULTI_CENTRE:
      return AlgoliaIndex.MULTI_CENTRE;
    case TripTypes.CRUISES:
      return AlgoliaIndex.CRUISES;
    default:
      return AlgoliaIndex.TOURS;
  }
};

export const setGeography = (payload: Array<GeographyResult>) => ({ type: AlgoliaActions.SET_GEOGRAPHY, payload });
export const setGeographyQuery = (payload: string) => ({
  type: AlgoliaActions.SET_GEOGRAPHY_QUERY,
  payload
});

export const fetchDestinations = () => ({ type: AlgoliaActions.FETCH_DESTINATIONS });
export const setDestinations = (payload: Array<GeographyResult>) => ({
  type: AlgoliaActions.SET_DESTINATIONS,
  payload
});

export const setAirports = (payload: Array<AirportResult>, isTourTripType?: boolean) => ({
  type: AlgoliaActions.SET_AIRPORTS,
  payload,
  isTourTripType
});

export const setAirportsQuery = (payload: string, tripType?: TripTypes) => ({
  type: AlgoliaActions.SET_AIRPORTS_QUERY,
  payload,
  tripType
});

export const setHelpArticles = (payload: Array<GeographyResult>) => ({
  type: AlgoliaActions.SET_HELP_ARTICLES,
  payload
});
export const setHelpArticlesQuery = (payload: string) => ({
  type: AlgoliaActions.SET_HELP_ARTICLES_QUERY,
  payload
});

export const setToursByType = (payload: ToursByTypeProps) => ({
  type: AlgoliaActions.SET_TOURS_BY_TYPE,
  payload
});

export const fetchToursByType = (payload: TripTypes) => ({
  type: AlgoliaActions.FETCH_TOURS_BY_TYPE,
  payload
});

export const receiveToursByTypeSuccess = (payload: ToursByTypeProps) => ({
  type: AlgoliaActions.RECEIVE_TOURS_BY_TYPE_SUCCESS,
  payload
});

export const receiveToursByTypeFailure = (payload: TripTypes) => ({
  type: AlgoliaActions.RECEIVE_TOURS_BY_TYPE_FAILURE,
  payload
});

export function* onFetchToursByType() {
  yield takeEvery(AlgoliaActions.FETCH_TOURS_BY_TYPE, handleOnFetchToursByType);
}

export function* handleOnFetchToursByType({ payload: tripType }: any) {
  const cacheTours = yield select(getAlgoliaCacheToursByType(tripType));
  if (cacheTours) {
    yield putResolve(receiveToursByTypeSuccess({ tripType, tours: cacheTours }));
  } else {
    const algoliaIndex = getAlgoliaIndexByType(tripType);
    try {
      const { response } = yield call(performBrowseGeography, {
        index: algoliaIndex,
        filters: AlgoliaFilter.TOUR_DESTINATIONS
      });
      if (response) {
        const cmsDestinations = yield select(getAllCMSDestinationsSelector);
        const tours = response.map((tour) => {
          const { path, isoCode } = tour;
          if (!/^\w{2}$/.test(isoCode)) {
            const cmsDestination = findCmsDestinationByPath(path, cmsDestinations);
            if (cmsDestination) {
              return {
                ...tour,
                isoCode: cmsDestination.countryIsoCode
              };
            }
          }
          return tour;
        });
        yield putResolve(receiveToursByTypeSuccess({ tripType, tours }));
      } else {
        yield putResolve(receiveToursByTypeFailure(tripType));
      }
    } catch (e) {
      yield putResolve(receiveToursByTypeFailure(tripType));
    }
  }
}

export function* onSetGeographyQuery() {
  yield throttle(1000, AlgoliaActions.SET_GEOGRAPHY_QUERY, handleOnSetGeographyQuery);
}

export function* onSetAirportsQuery() {
  yield throttle(1000, AlgoliaActions.SET_AIRPORTS_QUERY, handleOnSetAirportsQuery);
}

export function* onSetHelpArticlesQuery() {
  yield throttle(1000, AlgoliaActions.SET_HELP_ARTICLES_QUERY, handleOnSetHelpArticlesQuery);
}

export function* onFetchDestinations() {
  yield takeEvery(AlgoliaActions.FETCH_DESTINATIONS, handleOnFetchDestinations);
}

export function* handleOnSetGeographyQuery({ payload }: any) {
  if (payload) {
    yield handleOnSearchGeography(payload);
  } else {
    const cacheDestinations = yield select(getAlgoliaCacheDestinations);
    if (cacheDestinations) {
      yield put(setGeography(cacheDestinations));
    } else {
      yield handleOnFetchDestinations();
    }
  }
}

export function* handleOnSearchGeography(payload: string) {
  const airports: Array<string> = yield select(getDealFinderAirports);
  const { response } = yield call(performSearchGeography, {
    index: AlgoliaIndex.GEOGRAPHY,
    query: payload,
    filters: new BooleanTagBuilder(airports, BooleanTagType.OR).build()
  });
  if (response) {
    yield put(setGeography(response.hits));
  }
}

export function* handleOnSetAirportsQuery({ payload, tripType }: any) {
  const cacheAirports = yield select(getAlgoliaCacheAirportsByType(tripType));
  const tourTripType = isTourTripType(tripType);
  if (!payload && cacheAirports?.length) {
    yield put(setAirports(cacheAirports, tourTripType));
  } else {
    const { response } = yield call(performFetchAirports, payload, tripType);
    if (response) {
      yield put(setAirports(response.hits, tourTripType));
    }
  }
}

export function* handleOnSetHelpArticlesQuery({ payload }: any) {
  const { response } = yield call(performFetchHelpArticles, payload);
  if (response) {
    yield put(setHelpArticles(response.hits));
  }
}

export function* handleOnFetchDestinations() {
  const { response } = yield call(performBrowseGeography, {
    index: AlgoliaIndex.GEOGRAPHY,
    filters: AlgoliaFilter.DESTINATIONS
  });
  if (response) {
    yield put(
      setGeography(
        response.sort((a: GeographyResult, b: GeographyResult) => a.name.display.localeCompare(b.name.display))
      )
    );
  }
}

export function performSearchGeography(config: SearchConfig) {
  const api: AlgoliaApi = new AlgoliaApi();
  return api
    .search<GeographyResult>(config)
    .then((response: any) => ({
      response
    }))
    .catch((error: any) => ({
      error
    }));
}

export function performFetchAirports(payload: any, tripType?: TripTypes) {
  const api: AlgoliaApi = new AlgoliaApi();
  return api
    .search<AirportResult>({ index: AlgoliaIndex.AIRPORT, query: payload, tripType })
    .then((response: any) => ({
      response
    }))
    .catch((error: any) => ({
      error
    }));
}

export function performFetchHelpArticles(payload: any) {
  const api: AlgoliaApi = new AlgoliaApi();
  return api
    .search<HelpArticleResult>({ index: AlgoliaIndex.HELP_CENTRE, query: payload })
    .then((response: any) => ({
      response
    }))
    .catch((error: any) => ({
      error
    }));
}

export function performBrowseGeography(config: BrowseConfig) {
  const api: AlgoliaApi = new AlgoliaApi();
  return api
    .browse(config)
    .then((response: any) => ({
      response
    }))
    .catch((error: any) => ({
      error
    }));
}

export interface ToursSearchParams {
  query: string;
  tripType: TripTypes;
}

export const setToursQueryByType = (payload: ToursSearchParams) => ({
  type: AlgoliaActions.SET_TOURS_QUERY_BY_TYPE,
  payload
});

export function* onSetToursQueryByType() {
  yield throttle(1000, AlgoliaActions.SET_TOURS_QUERY_BY_TYPE, handleOnSetToursQueryByType);
}

export function* handleOnSetToursQueryByType({ payload }: any) {
  const { query, tripType } = payload;
  if (query) {
    yield handleOnSearchTours(payload);
  } else {
    yield handleOnFetchToursByType({ payload: tripType });
  }
}

export function* handleOnSearchTours(payload: ToursSearchParams) {
  const { tripType } = payload;
  const { response } = yield call(performSearchTours, payload);
  if (response) {
    yield putResolve(setToursByType({ tripType, tours: response.hits }));
  } else {
    yield putResolve(receiveToursByTypeFailure(tripType));
  }
}

export function performSearchTours(payload: ToursSearchParams) {
  const { tripType, query } = payload;
  const algoliaIndex = getAlgoliaIndexByType(tripType);
  const config = {
    index: algoliaIndex,
    query
  };

  const api: AlgoliaApi = new AlgoliaApi();
  return api
    .search<GeographyResult>(config)
    .then((response: any) => ({
      response
    }))
    .catch((error: any) => ({
      error
    }));
}

export const algoliaReducer: any = (
  state: AlgoliaState = INITIAL_ALGOLIA_STATE,
  { type, payload, isTourTripType }: any
) => {
  switch (type) {
    case AlgoliaActions.SET_GEOGRAPHY: {
      const cacheDestinations = state.cache.destinations || payload;
      return {
        ...state,
        geography: { ...state.geography, data: payload, loading: false },
        cache: { ...state.cache, destinations: cacheDestinations }
      };
    }
    case AlgoliaActions.SET_GEOGRAPHY_QUERY: {
      const loading = !!payload || !state.geography.data.length;
      return { ...state, geography: { ...state.geography, query: payload, loading } };
    }
    case AlgoliaActions.SET_AIRPORTS: {
      const cacheTripType = isTourTripType ? AlgoliaAirportCacheTypes.TOURS : AlgoliaAirportCacheTypes.HOLIDAYS;
      const cacheAirports = (state.cache.airports && state.cache.airports[cacheTripType]) || payload;
      return {
        ...state,
        airports: { ...state.airports, data: { ...state.airports.data, [cacheTripType]: payload }, loading: false },
        cache: { ...state.cache, airports: { ...state.cache.airports, [cacheTripType]: cacheAirports } }
      };
    }
    case AlgoliaActions.SET_AIRPORTS_QUERY: {
      const loading = !!payload;
      return { ...state, airports: { ...state.airports, query: payload, loading } };
    }
    case AlgoliaActions.SET_HELP_ARTICLES:
      return { ...state, helpArticles: { ...state.helpArticles, data: payload, loading: false } };
    case AlgoliaActions.SET_HELP_ARTICLES_QUERY:
      return { ...state, helpArticles: { ...state.helpArticles, query: payload, loading: true } };
    case AlgoliaActions.FETCH_TOURS_BY_TYPE: {
      const tripType = payload;
      const stateName = getToursStateNameByType(tripType);
      return { ...state, [stateName]: { ...state[stateName], loading: true } };
    }
    case AlgoliaActions.RECEIVE_TOURS_BY_TYPE_SUCCESS: {
      const { tripType, tours } = payload;
      const stateName = getToursStateNameByType(tripType);
      const cacheTours = state.cache[stateName] || tours;
      const validToursAirports = uniqBy(
        flatten(map(tours, 'airports').filter((airports) => Array.isArray(airports))),
        'code'
      );
      const storedAirports = state.airports.data.tours;
      const validAirports = compact(
        validToursAirports.map(({ code }) =>
          storedAirports.find(({ airport: { code: airportCode } }) => code === airportCode)
        )
      );
      return {
        ...state,
        [stateName]: { ...state[stateName], data: tours, airports: validAirports, loading: false },
        cache: { ...state.cache, [stateName]: cacheTours }
      };
    }
    case AlgoliaActions.RECEIVE_TOURS_BY_TYPE_FAILURE: {
      const stateName = getToursStateNameByType(payload);
      return { ...state, [stateName]: { ...state[stateName], data: [], loading: false } };
    }
    case AlgoliaActions.SET_TOURS_QUERY_BY_TYPE: {
      const { tripType, query } = payload;
      const stateName = getToursStateNameByType(tripType);
      const loading = !!payload || !state[stateName].data.length;
      return { ...state, [stateName]: { ...state[stateName], query, loading } };
    }
    case CommonSearchActions.SET_TAB_INDEX: {
      const cacheAirportsKey =
        payload === TAB_INDEXES.HOLIDAYS ? AlgoliaAirportCacheTypes.HOLIDAYS : AlgoliaAirportCacheTypes.TOURS;
      const cacheAirports = state.cache.airports && state.cache.airports[cacheAirportsKey];
      return {
        ...state,
        airports: {
          ...state.airports,
          data: { ...state.airports.data, ...(cacheAirports && { [cacheAirportsKey]: cacheAirports }) }
        }
      };
    }
    case AlgoliaActions.SET_TOURS_BY_TYPE: {
      const { tripType, tours } = payload;
      const stateName = getToursStateNameByType(tripType);
      return { ...state, [stateName]: { ...state[stateName], data: tours, loading: false } };
    }

    default:
      return state;
  }
  //
};
