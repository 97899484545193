// Image is used as a fallback when an image request fails
import { ImagesResponse } from '@model/iceberg';

export const HERO_IMAGES_FALLBACK: ImagesResponse = {
  images: ['/assets/images/my-booking-fallback-image.jpg'],
  destination: {
    name: '',
    parent: '',
    path: '',
    type: ''
  }
};

export const TILE_IMAGES_FALLBACK: Array<string> = ['/assets/images/fallback-images/placeholder_600x338.jpg'];

export const IMAGE_FALLBACK_LARGE = '/assets/images/fallback-images/placeholder_1280x720.jpg';

export const TILE_FALLBACK_IMAGE: string = '/assets/images/fallback-images/placeholder_600x338.jpg';
