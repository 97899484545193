import { AsyncData } from '@model/common';
import { AirportResult, GeographyResult, HelpArticleResult, TourHit } from '@model/search';

export interface AlgoliaComponentState<T> extends AsyncData<T> {
  query: string;
}

export enum AlgoliaAirportCacheTypes {
  TOURS = 'tours',
  HOLIDAYS = 'holidays'
}

export type AlgoliaAirportCache = {
  [key in AlgoliaAirportCacheTypes]: Array<AirportResult> | null;
};

export interface AlgoliaCache {
  airports: AlgoliaAirportCache | null;
  destinations: Array<GeographyResult> | null;
  escortedTours: Array<TourHit> | null;
  privateTours: Array<TourHit> | null;
  riverCruises: Array<TourHit> | null;
  multiCentres: Array<TourHit> | null;
  cruises: Array<TourHit> | null;
}

export interface AlgoliaTourComponentState<T> extends AlgoliaComponentState<T> {
  airports: Array<AirportResult>;
}

export type AlgoliaAirports = {
  [key in AlgoliaAirportCacheTypes]: Array<AirportResult>;
};

export interface AlgoliaState {
  geography: AlgoliaComponentState<Array<GeographyResult>>;
  airports: AlgoliaComponentState<AlgoliaAirports>;
  helpArticles: AlgoliaComponentState<Array<HelpArticleResult>>;
  escortedTours: AlgoliaTourComponentState<Array<TourHit>>;
  privateTours: AlgoliaTourComponentState<Array<TourHit>>;
  riverCruises: AlgoliaTourComponentState<Array<TourHit>>;
  multiCentres: AlgoliaTourComponentState<Array<TourHit>>;
  cruises: AlgoliaTourComponentState<Array<TourHit>>;
  cache: AlgoliaCache;
}
