import { ClimateMonths, HotelTile, ImagesResponse, Testimonial } from '@model/iceberg';
import { Country, Destination, Hotel, Region, Resort, TripTypes } from '@model/common';
import { TourDestinationDetails, TourDetails } from '@model/tours';
import { ContentDestination, PageTemplates, TravelGuide, VideoGuide } from './common';

export interface PageData {
  pageType: PageTemplates;
  name: string;
  path: string;
  tripType: TripTypes;
}

export interface PageConfig {
  type: string;
  name: PageTemplates;
  components: Array<TemplateComponent>;
}

export interface TemplateData {
  components: Array<TemplateComponent>;
}

export interface Separators {
  above?: string;
  below?: string;
}

export type TemplateComponent =
  | BaseComponent
  | GenericComponent
  | TitleComponent
  | DescriptionComponent
  | SeoComponent
  | CtaComponent
  | BenefitGroupComponent
  | BreadrumbComponent
  | ClimateComponent
  | DestinationGroupComponent
  | HeroImageComponent
  | HotelTileGroupComponent
  | SeparatorComponent
  | SpecialOffersComponent
  | TestimonialComponent
  | TourDestinationComponent
  | TourGroupComponent
  | TravelGuideComponent;

export interface BaseComponent {
  handle: string;
  path: string;
  tripType?: TripTypes;
  destination?: Destination;
  separators?: Separators;
  data?: any;
}

// Extensible Component types

export interface GenericComponent extends BaseComponent {
  value: string;
}

export interface TitleComponent extends BaseComponent {
  title: string;
  subTitle?: string | null;
}

export interface DescriptionComponent extends GenericComponent {
  showReadMore?: boolean;
}

export interface SeoComponent extends TitleComponent {
  description: string;
}

export interface CtaComponent extends BaseComponent {
  ctaTitle: string | null;
  ctaUrl?: string;
  hideCta: boolean;
}

// Components

export enum BenefitGroupType {
  DEFAULT = 'default',
  TRUST_BAR = 'trustBar'
}

export interface BenefitGroupComponent extends TitleComponent {
  type: BenefitGroupType;
  benefits: Array<ContentBenefit>;
}

export interface BreadrumbComponent extends BaseComponent {
  breadcrumbs: Array<Country | Region | Resort | Hotel>;
}

export interface ClimateComponent extends BaseComponent {
  temperatures: ClimateMonths;
  rainfall: ClimateMonths;
  sunshine: ClimateMonths;
  sunrise: null;
  description: string;
}

export interface ContentBenefit extends TitleComponent, CtaComponent {
  details: string;
  icon: string;
}

export enum DestinationGroupTypes {
  STANDARD = 'standard',
  REGION_LIST = 'region-list',
  RESORT_LIST = 'resort-list'
}

export interface DestinationGroupComponent extends TitleComponent, CtaComponent {
  type: DestinationGroupTypes;
  tripTypeOverride: string | null;
  destinations: Array<ContentDestination>;
}

export interface HeroImageComponent extends Omit<BaseComponent, 'destination'>, Pick<ImagesResponse, 'destination'> {
  type: string;
  title?: string;
  images: Array<string>;
}

export interface HotelTileGroupComponent extends TitleComponent, Pick<CtaComponent, 'ctaTitle'> {
  tripTypeOverride: string | null;
  tiles: Array<HotelTile>;
  ctaPath: string;
}

export interface SeparatorComponent extends BaseComponent {
  height: number;
}

export interface SpecialOffersComponent extends BaseComponent {
  offers: Array<string>;
}

export interface TestimonialComponent extends BaseComponent {
  testimonials: Array<Testimonial>;
}

export interface TourDestinationComponent extends TitleComponent, CtaComponent {
  packages: Array<TourDestinationDetails>;
}

export interface TourGroupComponent extends TitleComponent, CtaComponent {
  packages: Array<TourDetails>;
}

export interface TravelGuideComponent extends BaseComponent {
  guides: Array<TravelGuide>;
}

export interface VideoGuideComponent extends BaseComponent {
  guides: Array<VideoGuide>;
}
