import { uniqBy, filter, negate } from 'lodash';
import { TourByTypeProps, TourHit } from '@model/search';
import { TourSearchState, TourSearchParams } from '@model/state/search-forms-state';
import { FILTER_DEFAULTS } from '@model/price-calendar';
import { ToursApiParams } from '@model/tours';
import { getToursStateNameByType, isSingleTour } from '@util/tours';

export enum TourSearchActions {
  SET_TOUR_BY_TYPE = '@TOUR_SEARCH/SET_TOUR_BY_TYPE',
  UNSET_TOUR_BY_TYPE = '@TOUR_SEARCH/UNSET_TOUR_BY_TYPE',
  CLEAR_TOURS_BY_TYPE = '@TOUR_SEARCH/CLEAR_TOURS_BY_TYPE',
  SET_SEARCH_PARAMS = '@TOUR_SEARCH/SET_SEARCH_PARAMS',
  SET_TOUR_LANDING_SEARCH = '@TOUR_SEARCH/SET_TOUR_LANDING_SEARCH',
  HYDRATE_SEARCH = '@TOUR_SEARCH/HYDRATE_SEARCH'
}

export const INITIAL_TOUR_SEARCH_STATE: TourSearchState = {
  escortedTours: [],
  privateTours: [],
  riverCruises: [],
  multiCentres: [],
  cruises: [],
  searchParams: {
    duration: null,
    month: null,
    airports: null,
    occupancy: FILTER_DEFAULTS.occupancy
  },
  tourLandingSearch: false
};

export const setTourByType = (payload: TourByTypeProps) => ({ type: TourSearchActions.SET_TOUR_BY_TYPE, payload });

export const unsetTourByType = (payload: TourByTypeProps) => ({ type: TourSearchActions.UNSET_TOUR_BY_TYPE, payload });

export const clearToursByType = (payload: string) => ({ type: TourSearchActions.CLEAR_TOURS_BY_TYPE, payload });

export const hydrateSearch = (payload: ToursApiParams) => ({
  type: TourSearchActions.HYDRATE_SEARCH,
  payload
});

export const setSearchParams = (payload: TourSearchParams) => ({ type: TourSearchActions.SET_SEARCH_PARAMS, payload });

export const setTourLandingSearch = (payload: boolean) => ({
  type: TourSearchActions.SET_TOUR_LANDING_SEARCH,
  payload
});

const uniqName = (tour: TourHit): string => tour.name;

export const addTour = (tour: TourHit, tours: Array<TourHit>): Array<TourHit> => {
  if (isSingleTour(tour)) {
    // not an "All tours in X" tour
    return [tour];
  }
  return uniqBy(filter(tours || [], negate(isSingleTour)).concat([tour]), uniqName);
};

export const removeTour = (tour: TourHit, tours: Array<TourHit>): Array<TourHit> => {
  return (tours || []).filter((selectedTour) => selectedTour.objectID !== tour.objectID);
};

export const tourSearchReducer: any = (state: any = INITIAL_TOUR_SEARCH_STATE, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case TourSearchActions.SET_TOUR_BY_TYPE: {
      const { tripType, tour } = payload;
      const stateName = getToursStateNameByType(tripType);
      const existingTours = state[stateName];
      return { ...state, [stateName]: addTour(tour, existingTours) };
    }
    case TourSearchActions.UNSET_TOUR_BY_TYPE: {
      const { tripType, tour } = payload;
      const stateName = getToursStateNameByType(tripType);
      const existingTours = state[stateName];
      return {
        ...state,
        [stateName]: removeTour(tour, existingTours)
      };
    }
    case TourSearchActions.CLEAR_TOURS_BY_TYPE: {
      const stateName = getToursStateNameByType(payload);
      return {
        ...state,
        [stateName]: INITIAL_TOUR_SEARCH_STATE[stateName]
      };
    }
    case TourSearchActions.SET_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          ...payload
        }
      };
    case TourSearchActions.SET_TOUR_LANDING_SEARCH:
      return {
        ...state,
        tourLandingSearch: payload
      };
    case TourSearchActions.HYDRATE_SEARCH: {
      const {
        duration,
        month,
        from: airports,
        occupancy,
        escortedTours,
        privateTours,
        multiCentres,
        riverCruises,
        cruises
      } = payload;
      return {
        ...state,
        ...(escortedTours && { escortedTours }),
        ...(privateTours && { privateTours }),
        ...(riverCruises && { riverCruises }),
        ...(multiCentres && { multiCentres }),
        ...(cruises && { cruises }),
        searchParams: {
          ...state.searchParams,
          ...(duration && { duration }),
          ...(month && { month }),
          ...(airports && { airports }),
          ...(occupancy && { occupancy })
        }
      };
    }
    default:
      return state;
  }
};
