/* *** MODELS *** */
export enum RadioToggleOptions {
  YES = 'Yes',
  NO = 'No'
}

export enum PersonTitle {
  MR = 'Mr',
  MRS = 'Mrs',
  MS = 'Ms',
  MISS = 'Miss'
}

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other'
}

export const TEXT_FIELD_MAX_LENGTH: number = 80;

export interface FormState {
  isValid: boolean;
  isTouched: boolean;
}

/* *** VALUES *** */
export const initialFormState = {
  isValid: false,
  isTouched: false
};

export const titleDropdownOptions = () => [
  {
    name: 'common__guest-title--mr',
    value: PersonTitle.MR
  },
  {
    name: 'common__guest-title--mrs',
    value: PersonTitle.MRS
  },
  {
    name: 'common__guest-title--ms',
    value: PersonTitle.MS
  },
  {
    name: 'common__guest-title--miss',
    value: PersonTitle.MISS
  }
];

export const childTitleDropdownOptions = () => [
  {
    name: 'common__guest-title--mr',
    value: PersonTitle.MR
  },
  {
    name: 'common__guest-title--miss',
    value: PersonTitle.MISS
  }
];

export const genderDropdownOptions = () => [
  {
    name: 'common__gender--male',
    value: Gender.MALE
  },
  {
    name: 'common__gender--female',
    value: Gender.FEMALE
  },
  {
    name: 'common__gender--other',
    value: Gender.OTHER
  }
];
