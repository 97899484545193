import { uniq, flatMap } from 'lodash';
import { Destination, Name, Place } from '@model/iceberg/geography';
import { LONDON_AIRPORTS } from '@model/iceberg/airports/london-airports';
import { getDefaultSearchMonth } from './date-time';
import { OfferType } from '@model/offers/offers';
import { GeographyResult } from '@model/search';
import { FILTER_DEFAULTS } from '@model/price-calendar';
import { Country, Region, isRegion } from '@model/common';

export interface DestinationSearchParams {
  destinations?: Array<GeographyResult>;
  path: string;
  display: string;
  offerType?: OfferType;
  nights?: number;
  airports?: Array<string>;
}

export const getDestinationDetails: (destination?: Destination) => Name = (destination?: Destination) => ({
  path: (destination?.region && destination.region.path) || destination?.country.name.path || '',
  display: (destination?.region && destination?.region.display) || destination?.country.name.display || ''
});

// Checks for an exact match
export function getDestinationByPath(path: string, destinations: Array<Destination>): Destination | undefined {
  return destinations.find((dest: Destination) => {
    if (dest.region?.path) {
      return dest.region.path === path;
    } else {
      return dest.country.name.path === path;
    }
  });
}

export const getNameFromPlace = (place: Place) => place?.region || place?.country.name;

export const getDestinationNameFromPlace = (place: Place) => getNameFromPlace(place).display;

export const getDestinationSearchParams = (props: DestinationSearchParams) => {
  const { destinations, path, display, offerType, nights, airports = [] } = props;
  const destination = { path, display, airports };
  const adults = offerType === OfferType.NO_SINGLE_SUPPLEMENT ? 1 : undefined;

  return {
    destinations:
      destinations && destinations.length
        ? destinations.map(({ name, airports }) => ({
            display: name.display,
            path: name.path,
            airports: Object.keys(airports)
          }))
        : [destination],
    airports: LONDON_AIRPORTS,
    duration: nights || FILTER_DEFAULTS.duration,
    occupancy: [
      {
        adults: adults || 2,
        children: []
      }
    ],
    month: getDefaultSearchMonth()
  };
};

export const getDestinationTags = (result: GeographyResult): Array<string> => {
  return result._tags.filter((code) => code.length === 3);
};

export const getDestinationAirports = (destinations: GeographyResult[]) => {
  return destinations.length > 0 ? uniq(flatMap(destinations, getDestinationTags)) : [];
};

export const getDestinationResorts = (destination: Country | Region) =>
  isRegion(destination) ? destination.resorts : flatMap(destination.regions, 'resorts');
