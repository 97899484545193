import { Page } from '@model/common';

export const BookingPages = [
  Page.SEARCH,
  Page.PRODUCT,
  Page.FLIGHTS,
  Page.REVIEW,
  Page.GUESTS,
  Page.PAYMENT,
  Page.PAYMENT_ADYEN,
  Page.CONFIRMATION,
  Page.CONFIRMATION_ADYEN,
  Page.DEV_SEARCH,
  Page.TOURS_EXTRAS
];
