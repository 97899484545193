import { compact, flatten, head, sortBy } from 'lodash';
import {
  CMSDestination,
  CMSDynamicComponentTypes,
  CMSTripType,
  ComponentTypes,
  Entry,
  TripTypes
} from '@model/contentful';
import { Category, ImageApi } from '@model/iceberg';
import { ComponentData, setComponentsData } from './componentsDataOperations';
import { Store } from 'redux';
import { GlobalAppState } from '@model/state';
import { CMSComponentTypes } from '@components/cms';
import { ToursApi } from '@model/iceberg/service/tours';
import { Tours } from '@model/tours';
import { ContentfulApi } from '@model/contentful/service';
import { HotelContentApi, TestimonialsApi } from '@model/iceberg/service';
import { getCMSOpeningTimes } from '@state/cms';
import { getTripTypeFilter } from '@state/navigation';
import { truncateGallery } from '@util/images';

export const fetchComponentsData = async (
  cmsComponents: Array<any>,
  cmsComponentsType: CMSComponentTypes,
  store: Store
) => {
  const fetchComponentData = async (cmsComponent: any) => {
    const componentType: ComponentTypes = cmsComponent?.sys.contentType?.sys.id;
    const state: GlobalAppState = store.getState();
    const INITIAL_DATA: ComponentData = { componentType, data: {} };

    switch (componentType) {
      case ComponentTypes.productTileGroupComponent: {
        const { products } = cmsComponent.fields;

        if (!products) return INITIAL_DATA;

        const hotelTiles = await new HotelContentApi().tiles(
          [products.map(({ fields: { path } = { path: '' } }) => path)],
          products.length
        );
        const truncatedHotelTiles = hotelTiles?.data.map(({ gallery, place, featuredImage, ...rest }) => {
          const featuredImageUrl = featuredImage.url;
          return {
            place: {
              region: place.region,
              resort: place.resort,
              hotel: place.hotel
            },
            featuredImage,
            gallery: truncateGallery(gallery, featuredImageUrl),
            ...rest
          };
        });
        return { componentType, data: truncatedHotelTiles || [] };
      }
      case ComponentTypes.destinationGroupComponent: {
        const destinations: Array<Entry<CMSDestination>> = cmsComponent.fields.destinations;
        if (!destinations?.length) return INITIAL_DATA;
        const images: Array<Array<string>> = await Promise.all(
          destinations.map(async (destination: Entry<CMSDestination>) => {
            const path: string = destination.fields.destinationPath.fields.path;
            const imageApi: ImageApi = new ImageApi();

            const response = await imageApi.images({
              category: Category.SCENERY,
              path,
              count: 2
            });
            return response.images;
          })
        );
        return {
          componentType,
          data: { images }
        };
      }
      case ComponentTypes.toursProductTileGroupComponent: {
        const tripType = getTripTypeFilter(state);
        const privateTour = tripType === TripTypes.PRIVATE_TOURS;
        const tours: Array<Entry<any>> = cmsComponent.fields.tours;
        const tourPaths = compact(tours.map((tour: any) => tour.fields?.tourProductPath.fields.path));
        const toursApi = new ToursApi();
        const toursRequests = tourPaths.map((path) => toursApi.getTourDetails(path, undefined, privateTour));
        const tourDetailsResponse = compact(await Promise.all(toursRequests));
        const tourDetails: Tours = flatten(tourDetailsResponse?.map(({ data }) => data?.tours) || []);

        if (!tourDetails?.length) return INITIAL_DATA;

        return {
          componentType,
          data: tourDetails
        };
      }
      case ComponentTypes.contactDetails: {
        const openingTimes = getCMSOpeningTimes(state);
        return {
          componentType,
          data: { openingTimes }
        };
      }

      case ComponentTypes.genericDynamicComponent: {
        const genericDynamicComponentId = cmsComponent.fields.componentId;

        switch (genericDynamicComponentId) {
          case CMSDynamicComponentTypes.homepageTripTypes: {
            const imageAPI = new ImageApi();
            const contentfulAPI = new ContentfulApi();
            const cmsTripTypes = await contentfulAPI.getAllTripTypes();
            const homepageTripTypes: Array<CMSTripType> = sortBy(
              cmsTripTypes.items
                .map((tripType: any) => ({
                  ...tripType.fields
                }))
                .filter((tripType: CMSTripType) => tripType?.tripTypeId !== TripTypes.ALL),
              'order'
            );

            const imageSets = await Promise.all(
              homepageTripTypes.map((tripType) => {
                return imageAPI.images({
                  category: Category.TRIPTYPES,
                  count: 1,
                  path: tripType.tripTypeId
                });
              })
            );

            return {
              componentType,
              data: {
                tripTypes: homepageTripTypes,
                images: imageSets.map(({ images }) => head(images))
              }
            };
          }
          case CMSDynamicComponentTypes.testimonialsComponent: {
            const testimonials = await new TestimonialsApi().get(4);
            return {
              componentType,
              data: {
                testimonials
              }
            };
          }
          default:
            return INITIAL_DATA;
        }
      }
      default:
        return INITIAL_DATA;
    }
  };

  const componentsDataResponse = await Promise.all(
    cmsComponents.map(async (cmsComponent: any) => await fetchComponentData(cmsComponent))
  );

  store.dispatch(setComponentsData(cmsComponentsType, componentsDataResponse));
};
