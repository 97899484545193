import { Amount, Page, Price } from '@model/common';
import { getBookingPageFromUrl } from '@model/common/pages/get-booking-page-from-url';
import { GlobalAppState } from '@model/state';
import { DataLayerPayload } from '@model/analytics/data-layer-payload';
import {
  DataLayerPayloadBuilder,
  PageType,
  SearchType,
  tourSearchTypeMapping
} from '@model/analytics/data-layer-payload-builder';
import { DestinationLinkBuilder } from '@model/iceberg';
import TagManager from 'react-gtm-module';
import { Logging } from '@util/logging';
import { put, takeLatest } from 'redux-saga/effects';
import { BasketActions } from '@state/basket';
import { DataLayerDestinationMapper, DataLayerDestinationMapperType } from './data-layer-destination-mapper';
import { HotelActions, SetHotelProps } from '@state/hotel/hotelOperations';
import { getAbta } from '@state/agent/agentSelectors';
import { select } from '@redux-saga/core/effects';
import { getPath } from '@state/app/routing/routingSelectors';
import { getDestination, RoutingActions } from '@state/app';
import { TourSummaryState } from '@state/tours';
import { tripTypeLabels, TripTypes } from '@model/common/tours/trip-types';
import { getTripTypeFilter } from '@state/navigation';
import { startCase, isNil } from 'lodash';
import { isServer } from '@util/is-server';
import { MarkdownSanitizer } from '@util/markdown/markdown-sanitizer';
import { getImageUrl } from '@util/images';
import { getQueryAsObject } from '@util/common';
import { breakpoints } from '@styles/breakpoints';
import { SearchType as DealFinderSearchType } from '@model/iceberg/deal-finder/deal-finder';
import { getDestinationNameFromPlace } from '@util/destination';

export enum DataLayerMiddlewareAction {
  SEND_DATA_LAYER = '@DATA_LAYER/SEND_DATA_LAYER',
  SEND_BOOKING_DATA_LAYER = '@DATA_LAYER/SEND_BOOKING_DATA_LAYER',
  SEND_TOURS_DATA_LAYER = '@DATA_LAYER/SEND_TOURS_DATA_LAYER'
}

export enum DataLayerEvent {
  DATA_READY = 'dataReady',
  BASKET_UPDATE = 'basketUpdate',
  COOKIES_ACCEPTED = 'userAccepts'
}

export const sendDataLayer = (payload: DataLayerPayload, event: DataLayerEvent) => ({
  type: DataLayerMiddlewareAction.SEND_DATA_LAYER,
  payload,
  event
});

export const sendBookingDataLayer = () => ({
  type: DataLayerMiddlewareAction.SEND_BOOKING_DATA_LAYER
});

export const sendToursDataLayer = (payload) => ({
  type: DataLayerMiddlewareAction.SEND_TOURS_DATA_LAYER,
  payload
});

export function* onShouldSendBookingDataLayer() {
  yield takeLatest(
    [BasketActions.RECEIVE_BASKET_SUCCESS, DataLayerMiddlewareAction.SEND_BOOKING_DATA_LAYER],
    handleOnShouldSendBookingDataLayer
  );
}

export function* onShouldSendToursDataLayer() {
  yield takeLatest([DataLayerMiddlewareAction.SEND_TOURS_DATA_LAYER], handleOnShouldSendToursDataLayer);
}

export function* onShouldSendBasketUpdateDataLayer() {
  yield takeLatest([BasketActions.RECEIVE_ADD_TO_BASKET_SUCCESS], handleOnShouldSendBasketUpdateDataLayer);
}

export function* handleOnShouldSendBookingDataLayer() {
  const path: string = yield select(getPath);
  const state: GlobalAppState = yield select();
  const dataLayer: DataLayerPayload = getBookingDataLayerArgs(path, state);
  yield put(sendDataLayer(dataLayer, DataLayerEvent.DATA_READY));
}

export function* handleOnShouldSendToursDataLayer({ payload }: any) {
  const path: string = yield select(getPath);
  const state: GlobalAppState = yield select();
  const dataLayer: DataLayerPayload = getToursDataLayerArgs(path, state, payload);
  yield put(sendDataLayer(dataLayer, DataLayerEvent.DATA_READY));
}

export function* handleOnShouldSendBasketUpdateDataLayer() {
  const path: string = yield select(getPath);
  const state: GlobalAppState = yield select();
  const dataLayer: DataLayerPayload = getBookingDataLayerArgs(path, state);
  yield put(sendDataLayer(dataLayer, DataLayerEvent.BASKET_UPDATE));
}

export function* onShouldSendHotelDataLayer() {
  yield takeLatest(HotelActions.SET_HOTEL_PATH, handleOnShouldSendHotelDataLayer);
}

export function* handleOnShouldSendHotelDataLayer({ payload }: any) {
  const { data: hotelContent, shouldSendDataLayer }: SetHotelProps = payload;
  if (!shouldSendDataLayer || !hotelContent) {
    return;
  }
  const path: string = hotelContent.place.hotel!.path;
  const abta = yield select(getAbta);
  if (path) {
    const {
      price: { amount, currency },
      connectId,
      hero: { url },
      hotelRating,
      summary,
      place
    } = hotelContent;
    const { hotel, resort, region, country } = place;
    const placeName = getDestinationNameFromPlace(place);

    const sfHolidayType: string = tripTypeLabels[TripTypes.HOLIDAYS];
    const productLink: string = new DestinationLinkBuilder().getDestinationLink(path).as;
    const dataLayer: DataLayerPayload = new DataLayerPayloadBuilder()
      .withSalesForce({
        sfProductName: hotel?.display,
        sfImageLink: getImageUrl(url, breakpoints.xLarge),
        sfSalePrice: new Amount(amount, currency.code).formatNoSign(),
        sfRegularPrice: new Amount(amount, currency.code).formatNoSign(),
        sfDescription: MarkdownSanitizer.sanitize((summary || '').substring(0, 512)),
        sfStarRating: hotelRating.toString(),
        sfHolidayType,
        sfDestination: placeName,
        sfResort: resort.display,
        sfRegion: region.display,
        sfKeywords: `${sfHolidayType}~${country.name.display}~${region.display}~${resort.display}~${placeName} ${sfHolidayType}`,
        sfCategory: `${placeName} ${sfHolidayType}`
      })
      .withProductLink(productLink)
      .withPageType(PageType.PRODUCT)
      .withConnectId(connectId.toString())
      .withUuid()
      .withTradeId(abta)
      .build();
    yield put(sendDataLayer(dataLayer, DataLayerEvent.DATA_READY));
  }
}

export function* onShouldSendCatalogueDataLayer() {
  yield takeLatest(RoutingActions.INITIALIZE_PATH, handleOnShouldSendCatalogueDataLayer);
}

export function* handleOnShouldSendCatalogueDataLayer() {
  const path: string = yield select(getPath);
  const abta = yield select(getAbta);
  const destination: string = yield select(getDestination);
  const tripTypeFilter: TripTypes = yield select(getTripTypeFilter);
  const pageType: DataLayerDestinationMapperType = new DataLayerDestinationMapper(path).get();
  const tripType: string = tripTypeLabels[tripTypeFilter];
  const paths = destination.split('/');
  const name: string = startCase(paths[paths.length - 1]);

  if (pageType === DataLayerDestinationMapperType.DESTINATION) {
    yield put(
      sendDataLayer(
        new DataLayerPayloadBuilder()
          .withSalesForce({
            sfCategory: `${name} ${tripType}`
          })
          .withPageType(PageType.CATEGORY)
          .withUuid()
          .withTradeId(abta)
          .build(),
        DataLayerEvent.DATA_READY
      )
    );
  } else if (pageType === DataLayerDestinationMapperType.TOUR) {
    yield put(
      sendDataLayer(
        new DataLayerPayloadBuilder()
          .withSalesForce({
            sfCategory: `${name} ${tripType}`
          })
          .withPageType(PageType.CATEGORY)
          .withUuid()
          .withTradeId(abta)
          .build(),
        DataLayerEvent.DATA_READY
      )
    );
  }
}

export function* onSendDataLayer() {
  yield takeLatest(DataLayerMiddlewareAction.SEND_DATA_LAYER, handleSendDataLayer);
}

export function* handleSendDataLayer({ payload, event }: any) {
  if (!isServer) {
    const sendDataLayer = () => {
      if (!(window as any).dataLayer) {
        window.setTimeout(sendDataLayer, 50);
      } else {
        TagManager.dataLayer({ dataLayer: { ...payload, event } });
      }
    };
    sendDataLayer();
    yield Logging.log({
      text: 'Data Layer Pushed',
      data: payload
    });
  }
}

export function getBookingDataLayerArgs(path: string, state: GlobalAppState): DataLayerPayload {
  const page: Page | undefined = getBookingPageFromUrl(path);
  const { tripType, destinations = '' } = getQueryAsObject(path);
  const isTour = !!state.tours.tourReferences.packageReferences.length;
  const searchType = isTour
    ? tourSearchTypeMapping[tripType]
    : state.dealFinder.searchType === DealFinderSearchType.HOTEL_ONLY
      ? SearchType.HOTEL_ONLY
      : SearchType.HOLIDAY;
  const sfHolidayType = tripTypeLabels[tripType || 'holidays'];

  const searchDestinations = isTour ? destinations.split(',') : state.dealFinderLastSearched.destinations;
  const searchState = isTour ? state.search.tours.searchParams : state.dealFinderLastSearched;
  const searchMonth = isTour ? [searchState.month].join(',') : state.dealFinderLastSearched.month;
  const searchDuration = searchState.duration || 0;
  const searchRooms =
    isTour && !isNil(searchState.occupancy)
      ? searchState.occupancy.map(({ adults }) => ({ adults, children: [] }))
      : state.dealFinderLastSearched.occupancy;
  const connectId = isTour ? state.basket.data.connectId : state.basket.data.hotel?.connectId.toString();

  switch (page) {
    case Page.SEARCH:
      return new DataLayerPayloadBuilder()
        .withSearchStartDate(state.dealFinder.date)
        .withSearchMonth(state.dealFinder.month)
        .withSearchFlexible(state.dealFinder.flexibleDays)
        .withSearchDuration(state.dealFinder.duration)
        .withSearchDestinations(state.dealFinder.destinations)
        .withSearchRooms(state.dealFinder.occupancy)
        .withPricing(state.basket.data.pricing)
        .withHotel(state.basket.data.hotel?.place)
        .withFlight(state.basket.data.flight)
        .withCurrency(state.basket.data.pricing.total.currency.code)
        .withPageType(page)
        .withSearchType(searchType)
        .withConnectId(connectId)
        .withUuid()
        .withTradeId(state.agent.data.abta)
        .build();
    case Page.PRODUCT:
    case Page.FLIGHTS:
      return new DataLayerPayloadBuilder()
        .withSearchStartDate(state.dealFinderLastSearched.date)
        .withSearchMonth(state.dealFinderLastSearched.month)
        .withSearchFlexible(state.dealFinderLastSearched.flexibleDays)
        .withSearchDuration(state.dealFinderLastSearched.duration)
        .withSearchDestinations(state.dealFinderLastSearched.destinations)
        .withSearchRooms(state.dealFinderLastSearched.occupancy)
        .withPricing(state.basket.data.pricing)
        .withHotel(state.basket.data.hotel?.place)
        .withFlight(state.basket.data.flight)
        .withCurrency(state.basket.data.pricing.total.currency.code)
        .withPageType(page)
        .withSearchType(searchType)
        .withConnectId(connectId)
        .withUuid()
        .withTradeId(state.agent.data.abta)
        .build();
    case Page.REVIEW:
      return new DataLayerPayloadBuilder()
        .withSearchStartDate(state.dealFinderLastSearched.date)
        .withSearchMonth(state.dealFinderLastSearched.month)
        .withSearchFlexible(state.dealFinderLastSearched.flexibleDays)
        .withSearchDuration(state.dealFinderLastSearched.duration)
        .withSearchDestinations(state.dealFinderLastSearched.destinations)
        .withSearchRooms(state.dealFinderLastSearched.occupancy)
        .withPricing(state.basket.data.pricing)
        .withHotel(state.basket.data.hotel?.place)
        .withFlight(state.basket.data.flight)
        .withTransferType(state.basket.data.transfer)
        .withHoldLuggage(state.basket.data.luggage)
        .withCurrency(state.basket.data.pricing.total.currency.code)
        .withPageType(page)
        .withSearchType(searchType)
        .withConnectId(connectId)
        .withUuid()
        .withTradeId(state.agent.data.abta)
        .build();
    case Page.TOURS_EXTRAS: {
      const {
        basket: { data: basket },
        tours: { tourSummary }
      } = state;
      const sfHolidayType: string = tripTypeLabels[tripType];
      const salesForceData = getSalesForceData(
        tourSummary,
        sfHolidayType,
        basket.pricing.perPerson,
        basket.pricing.saving
      );

      return new DataLayerPayloadBuilder()
        .withSearchStartDate(state.dealFinderLastSearched.date)
        .withSearchMonth(searchMonth)
        .withSearchFlexible(state.dealFinderLastSearched.flexibleDays)
        .withSearchDuration(searchDuration)
        .withSearchDestinations(searchDestinations)
        .withSearchRooms(searchRooms)
        .withPricing(basket.pricing)
        .withHotel(basket.hotel?.place)
        .withFlight(basket.flight)
        .withTransferType(basket.transfer)
        .withHoldLuggage(basket.luggage)
        .withCurrency(basket.pricing.total.currency.code)
        .withPageType(page)
        .withSearchType(searchType)
        .withSalesForce(salesForceData)
        .withConnectId(tourSummary.connectId)
        .withUuid()
        .withTradeId(state.agent.data.abta)
        .build();
    }
    case Page.GUESTS:
    case Page.PAYMENT_ADYEN:
    case Page.PAYMENT:
      return new DataLayerPayloadBuilder()
        .withSearchStartDate(state.dealFinderLastSearched.date)
        .withSearchMonth(searchMonth)
        .withSearchFlexible(state.dealFinderLastSearched.flexibleDays)
        .withSearchDuration(searchDuration)
        .withSearchDestinations(searchDestinations)
        .withSearchRooms(searchRooms)
        .withPricing(state.basket.data.pricing)
        .withHotel(state.basket.data.hotel?.place)
        .withFlight(state.basket.data.flight)
        .withTransferType(state.basket.data.transfer)
        .withHoldLuggage(state.basket.data.luggage)
        .withCurrency(state.basket.data.pricing.total.currency.code)
        .withPageType(page)
        .withSearchType(searchType)
        .withSalesForce({
          sfHolidayType
        })
        .withConnectId(connectId)
        .withUuid()
        .withTradeId(state.agent.data.abta)
        .build();
    case Page.CONFIRMATION_ADYEN:
    case Page.CONFIRMATION:
      return new DataLayerPayloadBuilder()
        .withSearchStartDate(state.dealFinderLastSearched.date)
        .withSearchMonth(searchMonth)
        .withSearchFlexible(state.dealFinderLastSearched.flexibleDays)
        .withSearchDuration(searchDuration)
        .withSearchDestinations(searchDestinations)
        .withSearchRooms(searchRooms)
        .withPricing(state.basket.data.pricing)
        .withConfirmationEhId(state.basket.data.confirmationEhId)
        .withHotel(state.basket.data.hotel?.place)
        .withFlight(state.basket.data.flight)
        .withTransferType(state.basket.data.transfer)
        .withHoldLuggage(state.basket.data.luggage)
        .withTransactionId(state.basket.data.bookingReference)
        .withTransactionTotal(state.basket.data.pricing)
        .withCurrency(state.basket.data.pricing.total.currency.code)
        .withPageType(page)
        .withSearchType(searchType)
        .withSalesForce({
          sfHolidayType
        })
        .withConnectId(connectId)
        .withUuid()
        .withTradeId(state.agent.data.abta)
        .build();
    default:
      return {};
  }
}

export interface ToursDataLayerPayload {
  tripType: TripTypes;
  pageType: PageType;
  connectId: string;
}

export function getToursDataLayerArgs(
  path: string,
  state: GlobalAppState,
  payload: ToursDataLayerPayload
): DataLayerPayload {
  const { tripType, pageType, connectId } = payload;
  const sfHolidayType: string = tripTypeLabels[tripType];

  const salesForceData = getSalesForceData(state.tours.tourSummary, sfHolidayType, state.tours.tourSummary.tourPrice);

  return new DataLayerPayloadBuilder()
    .withSalesForce(salesForceData)
    .withProductLink(path)
    .withPageType(pageType)
    .withUuid()
    .withTradeId(state.agent.data.abta)
    .withConnectId(connectId)
    .build();
}

export const getSalesForceData = (
  tourSummary: TourSummaryState,
  sfHolidayType: string,
  price: Price,
  saving?: Price
) => {
  const { tourName, tourDescription, tourImage, destinationName } = tourSummary;

  return {
    sfProductName: tourName,
    sfImageLink: tourImage,
    sfSalePrice: new Amount(price.amount, price.currency.code).formatNoSign(),
    sfRegularPrice: new Amount(price.amount + (saving?.amount || 0), price.currency.code).formatNoSign(),
    sfDescription: MarkdownSanitizer.sanitize(tourDescription.substring(0, 512)),
    sfDestination: destinationName,
    sfHolidayType,
    sfKeywords: `${sfHolidayType}~${destinationName}~${destinationName} ${sfHolidayType}`,
    sfCategory: `${destinationName} ${sfHolidayType}`
  };
};
