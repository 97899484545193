import { isEqual } from 'lodash';
import { FormikValues } from 'formik';
import cardValidator from 'card-validator';
import moment from 'moment';
import { Validation } from '@model/forms/validation/validation';
import { amountStringToFloat } from '@util/currency';
import { PaymentMethod } from '../payment-form';

export const validatePaymentForm = (values: FormikValues, isAgent: boolean, requiresPayment: boolean) => {
  const errors: any = {};
  const validFormErrors = {};
  const validatePaymentDetails: boolean = isAgent ? requiresPayment : true;

  if (isAgent) {
    if (!values.tradeEmail) {
      errors.tradeEmail = 'form__field-error--required';
    } else if (!Validation.isEmailValid(values.tradeEmail)) {
      errors.tradeEmail = 'form__field-error--email';
    }
  }

  if (validatePaymentDetails) {
    if (!values.address) {
      errors.address = 'form__field-error--required';
    }

    if (!values.townCity) {
      errors.townCity = 'form__field-error--required';
    }

    if (!values.postCode) {
      errors.postCode = 'form__field-error--required';
    }

    if (!values.county) {
      errors.county = 'form__field-error--required';
    }

    if (!values.cardNumber) {
      errors.cardNumber = 'form__field-error--required';
    }

    if (!cardValidator.number(values.cardNumber).isPotentiallyValid) {
      errors.cardNumber = 'form__field-error--not-valid';
    }

    if (!values.cardExpiryDate) {
      errors.cardExpiryDate = 'form__field-error--required';
    } else {
      const expiry = moment(values.cardExpiryDate, 'MM/YY');
      const today = moment();
      if (expiry.isBefore(today) || !expiry.isValid()) {
        errors.cardExpiryDate = 'form__field-error--invalid-date';
      }
    }

    if (!values.cvv) {
      errors.cvv = 'form__field-error--required';
    }

    if (!values.nameOnCard) {
      errors.nameOnCard = 'form__field-error--required';
    }
  }

  if (values.paymentMethod === PaymentMethod.CUSTOM_AMOUNT) {
    if (!values.amount) {
      errors.amount = 'form__field-error--required';
    } else {
      const amountAsFloat = amountStringToFloat(values.amount);
      if (isNaN(amountAsFloat) || amountAsFloat <= 0.01) {
        errors.amount = 'form__field-error--amount-not-valid';
      } else {
        if (values.totalOutstandingAmount) {
          const customAmountInPence = amountAsFloat * 100;
          if (customAmountInPence > values.totalOutstandingAmount) {
            errors.amount = 'form__field-error--amount-too-big';
          }
        }
      }
    }
  }

  const isValid = isEqual(validFormErrors, errors);

  return { errors, isValid };
};

export const validateAddressForm = (
  values: FormikValues,
  isAgent: boolean,
  requiresPayment: boolean,
  paymentValid: boolean
) => {
  const errors: any = {};
  const validFormErrors = {};
  const validatePaymentDetails: boolean = isAgent ? requiresPayment : true;

  if (isAgent) {
    if (!values.tradeEmail) {
      errors.tradeEmail = 'form__field-error--required';
    } else if (!Validation.isEmailValid(values.tradeEmail)) {
      errors.tradeEmail = 'form__field-error--email';
    }
    if (requiresPayment) {
      if (!values.cardholderName) {
        errors.cardholderName = 'form__field-error--required';
      }
    }
  }

  if (validatePaymentDetails) {
    if (!paymentValid) {
      errors.payment = 'payment__modal--required-payment';
    }

    if (!values.buildingNameOrNumber && !values.companyName) {
      errors.buildingNameOrNumber = 'form__field-error--required';
    }

    if (!values.street) {
      errors.street = 'form__field-error--required';
    }

    if (!values.townCity) {
      errors.townCity = 'form__field-error--required';
    }

    if (!values.postCode) {
      errors.postCode = 'form__field-error--required';
    }

    if (!values.county) {
      errors.county = 'form__field-error--required';
    }
  }

  if (!values.termsAccepted) {
    errors.termsAccepted = 'form__field-error--terms';
  }

  if (values.paymentMethod === PaymentMethod.CUSTOM_AMOUNT) {
    if (!values.amount) {
      errors.amount = 'form__field-error--required';
    } else {
      const amountAsFloat = amountStringToFloat(values.amount);
      if (isNaN(amountAsFloat) || amountAsFloat <= 0.01) {
        errors.amount = 'form__field-error--amount-not-valid';
      } else {
        if (values.totalOutstandingAmount) {
          const customAmountInPence = amountAsFloat * 100;
          if (customAmountInPence > values.totalOutstandingAmount) {
            errors.amount = 'form__field-error--amount-too-big';
          }
        }
      }
    }
  }

  const isValid = isEqual(validFormErrors, errors);

  return { errors, isValid };
};
