import { isEqual } from 'lodash';
import { FormikValues } from 'formik';
import { Validation } from '@model/forms/validation/validation';

export const validateBookingEnquiryForm = (values: FormikValues) => {
  const errors: any = {};

  const validFormErrors = {};

  if (!values.name) {
    errors.name = 'form__field-error--required';
  }

  if (!values.email) {
    errors.email = 'form__field-error--required';
  } else if (!Validation.isEmailValid(values.email)) {
    errors.email = 'form__field-error--email';
  }

  if (!values.telephone) {
    errors.telephone = 'form__field-error--required';
  } else {
    const validation = Validation.isTelephoneValidDetails(values.telephone);
    if (!validation.lengthValid) {
      errors.telephone = 'form__field-error--telephone-length';
    } else if (!validation.formatValid) {
      errors.telephone = 'form__field-error--telephone';
    }
  }

  if (!values.bookingReference) {
    errors.bookingReference = 'form__field-error--required';
  }

  if (!values.dateOfTravel || !values.dateOfTravel.startDate || !values.dateOfTravel.endDate) {
    errors.dateOfTravel = 'form__field-error--required';
  }

  if (!values.enquiry) {
    errors.enquiry = 'form__field-error--required';
  }

  const isValid = isEqual(validFormErrors, errors);

  return { errors, isValid };
};
