import { SortOptions } from '@model/common';
import { Months } from '@model/common/dates';
import { TripTypes } from '@model/contentful';
import { HYDRATE } from 'next-redux-wrapper';
import { isEqual } from 'lodash';
import { GlobalActions } from '@state/store';
import { BaseAction } from '@model/redux';

/* ***************** *
 *       TYPES       *
 * ***************** */
export interface NavigationState {
  selectedFilter: TripTypes;
  selectedSortOption: SortOptions;
  selectedMonth: Months;
  selectedAirport: string;
}

export interface Action {
  type: NavigationActionTypes | GlobalActions;
  payload: any;
}

/* ***************** *
 *   ACTION TYPES    *
 * ***************** */
export enum NavigationActionTypes {
  SELECTED_FILTER_OPTION = '@NAV/SELECTED_FILTER_OPTION',
  SELECTED_SORT_OPTION = '@NAV/SELECTED_SORT_OPTION',
  SELECTED_MONTH = '@NAV/SELECTED_MONTH',
  SELECTED_AIRPORT = '@NAV/SELECTED_AIRPORT'
}

/* ***************** *
 *     ACTIONS       *
 * ***************** */
export interface SetSelectedFilter extends BaseAction {
  type: NavigationActionTypes.SELECTED_FILTER_OPTION;
  payload: string;
}

export function setTripTypeFilter(payload: string): SetSelectedFilter {
  return {
    type: NavigationActionTypes.SELECTED_FILTER_OPTION,
    payload
  };
}

export interface SetSelectedSortOption extends BaseAction {
  type: NavigationActionTypes.SELECTED_SORT_OPTION;
  payload: string;
}

export function setSelectedSortOption(payload: string): SetSelectedSortOption {
  return {
    type: NavigationActionTypes.SELECTED_SORT_OPTION,
    payload
  };
}

export interface SetSelectedMonth extends BaseAction {
  type: NavigationActionTypes.SELECTED_MONTH;
  payload: string;
}

export function setSelectedMonth(payload: string): SetSelectedMonth {
  return {
    type: NavigationActionTypes.SELECTED_MONTH,
    payload
  };
}

export interface SetSelectedAirport extends BaseAction {
  type: NavigationActionTypes.SELECTED_AIRPORT;
  payload: string;
}

export function setSelectedAirport(payload: string): SetSelectedAirport {
  return {
    type: NavigationActionTypes.SELECTED_AIRPORT,
    payload
  };
}

/* ***************** *
 *     REDUCER       *
 * ***************** */

export const NAVIGATION_INITIAL_STATE: NavigationState = {
  selectedFilter: TripTypes.HOLIDAYS,
  selectedSortOption: SortOptions.SORT_ALPHABETICAL,
  selectedMonth: Months.JAN,
  selectedAirport: 'LON'
};

const getValidTripType = (tripType: TripTypes) => (tripType === TripTypes.ADD_ONS ? TripTypes.HOLIDAYS : tripType);

export const navigationReducer = (state: NavigationState = NAVIGATION_INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case HYDRATE:
      if (isEqual(state, NAVIGATION_INITIAL_STATE)) {
        return action.payload?.navigation || state;
      }
      return state;
    case NavigationActionTypes.SELECTED_FILTER_OPTION:
      return {
        ...state,
        selectedFilter: getValidTripType(action.payload) || null
      };
    case NavigationActionTypes.SELECTED_SORT_OPTION:
      return {
        ...state,
        selectedSortOption: action.payload
      };
    case NavigationActionTypes.SELECTED_MONTH:
      return {
        ...state,
        selectedMonth: action.payload
      };
    case NavigationActionTypes.SELECTED_AIRPORT:
      return {
        ...state,
        selectedAirport: action.payload
      };
    default:
      return state;
  }
};
