export enum PageTemplates {
  AGENTS = 'agentsPage',
  HOMEPAGE = 'homePage',
  DESTINATION = 'destinationPage',
  DESTINATIONS_LIST = 'destinationListPage',
  HOTEL = 'hotel',
  INFORMATION = 'informationPage',
  OFFERS = 'offersPage',
  PROMO = 'promoPage',
  RESORT = 'resortPage',
  TRAVEL_GUIDE_PAGE = 'travelGuidePage',
  TRIP_TYPE_PAGE = 'tripTypePage',
  VIDEO_GUIDE_PAGE = 'videoGuidePage'
}
