import { Separator } from '@styles/layout';

export const withSeparators =
  (WrappedComponent) =>
  ({ separators, ...props }) => {
    const ChildComponent = WrappedComponent(props);

    if (ChildComponent) {
      return (
        <>
          {separators && separators.above && <Separator value={separators.above} />}
          {ChildComponent}
          {separators && separators.below && <Separator value={separators.below} />}
        </>
      );
    }
    return null;
  };
