import { ImagesParams, ImagesResponse } from '@model/iceberg';
import uri from 'urijs';
import { EndPoint, getURL, Request, RequestType } from '@model/service';

interface ImageApiRequests {
  images: (payload: ImagesParams) => Promise<ImagesResponse>;
}

export class ImageApi implements ImageApiRequests {
  private request: Request = new Request();

  public async images(payload: ImagesParams): Promise<ImagesResponse> {
    const { category, count = 1, path } = payload;

    return await this.request
      .get(
        uri(getURL(RequestType.IMAGES))
          .path(`${EndPoint.IMAGES}/${category.toLowerCase()}/${count}/${path || ''}`)
          .href()
      )
      .catch(() => {
        return [];
      });
  }
}
