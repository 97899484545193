import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { isDesktop, isMobile } from '@styles/breakpoints';
import {
  getIsServer,
  setIsServer,
  getIsMobile,
  setIsMobile,
  getIsDesktop,
  setIsDesktop,
  setShowSearch
} from '@state/app/ui-state';

export const useUiState = () => {
  const dispatch = useDispatch();
  const isServer: boolean = useSelector(getIsServer);
  const isDesktopView = useSelector(getIsDesktop);
  const isMobileView = useSelector(getIsMobile);
  const [handlerCreated, setHandlerCreated] = useState(false);

  function handleResize() {
    const windowWidth = window.innerWidth;
    const desktop = isDesktop(windowWidth);
    const mobile = isMobile(windowWidth);
    dispatch(setIsDesktop(desktop));
    dispatch(setIsMobile(mobile));
    dispatch(setShowSearch(desktop));
  }

  useEffect(() => {
    dispatch(setIsServer(false));
  }, []);

  useEffect(() => {
    if (!isServer && !handlerCreated) {
      handleResize();
      const debouncedResize = debounce(handleResize, 300);
      window.addEventListener('resize', debouncedResize, false);
      setHandlerCreated(true);
      return () => {
        setHandlerCreated(false);
        window.removeEventListener('resize', debouncedResize, false);
      };
    }
  }, [isServer]);
  return [isDesktopView, isMobileView];
};
