import { ReactElement } from 'react';
import { mapValues } from 'lodash';
import { Localisation, SortDirection, Keyable, BoardBasis, TripTypes } from '@model/common';
import { Guests } from '@model/search';
import { isServer } from './is-server';
import { AllowedFormCharacters } from '@model/forms/validation';
import { EnvConfig } from '@model/config/env-config';
import { Environment } from '@model/config/environment';
import { Occupancy } from '@model/iceberg';

const DOMPurify = require('isomorphic-dompurify');

export const isDev = EnvConfig.get().APP_ENV !== Environment.PRODUCTION;

export const sortByField = (array: Array<any>, fieldName: string, direction: SortDirection) =>
  [...array].sort((a, b) => {
    let firstValue = a;
    let secondValue = b;

    if (fieldName) {
      let i = 0;
      const prop = fieldName.split('.');
      const len = prop.length;

      // NOTE: iterate through nested fields to get the value to compare
      while (i < len) {
        const isPropAnArray = prop[i].includes('[') && prop[i].includes(']');
        const propIndex = isPropAnArray
          ? parseInt(prop[i].substring(prop[i].indexOf('[') + 1, prop[i].indexOf(']')))
          : null;
        const propName = isPropAnArray ? prop[i].substring(0, prop[i].indexOf('[')) : prop[i];

        firstValue = firstValue
          ? isPropAnArray && typeof propIndex === 'number'
            ? firstValue[propName][propIndex]
            : firstValue[propName]
          : null;
        secondValue = secondValue
          ? isPropAnArray && typeof propIndex === 'number'
            ? secondValue[propName][propIndex]
            : secondValue[propName]
          : null;
        i += 1;
      }
    }

    if (direction === SortDirection.DESC) {
      if (firstValue < secondValue) {
        return 1;
      }
      if (secondValue < firstValue) {
        return -1;
      }
      return 0;
    }
    if (firstValue > secondValue) {
      return 1;
    }
    if (secondValue > firstValue) {
      return -1;
    }
    return 0;
  });

export const getNumberOfGuests: (rooms: Array<Guests>) => number = (rooms: Array<Guests>) =>
  rooms.reduce((accumulator: any, guests: any) => {
    return accumulator + guests.adults + guests.children.length;
  }, 0);

export const getOccupancyLabel = (occupancy: Occupancy, t: any) => {
  const adults: string =
    (!!occupancy.adults &&
      `${occupancy.adults} ${t(occupancy.adults === 1 ? 'common__label--adult' : 'common__label--adults')}`) ||
    '';
  const children: string =
    (!!occupancy.children &&
      `, ${occupancy.children} ${t(occupancy.children === 1 ? 'common__label--child' : 'common__label--children')}`) ||
    '';
  const infants: string =
    (!!occupancy.infants &&
      `, ${occupancy.infants} ${t(occupancy.infants === 1 ? 'common__label--infant' : 'common__label--infants')}`) ||
    '';
  return `${adults}${children}${infants}`;
};

export const getTranslation = (t: any, text?: string | Localisation) => {
  if (!text) {
    return null;
  }

  if (typeof text === 'string') {
    return t(text);
  }

  return t(text.label, text.vars);
};

export const getTranslatedHTML = (t: (label: string, vars: any) => string, label: string, vars?: any) => ({
  __html: DOMPurify.sanitize(t(label, vars))
});

export const getHTML = (html: string) => ({
  __html: DOMPurify.sanitize(html)
});

export const hex2rgba: (hex: string, a: number) => string = (hex: string, a: number) => {
  const hexValues = hex.substr(1).match(/../g);
  if (!hexValues) {
    return '';
  }

  return `rgba(${hexValues.map((x) => +`0x${x}`)},${a})`;
};

export const setBodyNoScroll = (isNoScroll: boolean, isMobileOnly?: boolean) => {
  if (!isServer) {
    const body = document.getElementsByTagName('body')[0];

    if (isNoScroll) {
      body.classList.add(isMobileOnly ? 'no-scroll-mobile' : 'no-scroll');
    } else {
      body.classList.remove('no-scroll');
      body.classList.remove('no-scroll-mobile');
    }
  }
};

export const isCharacterValid = (onlyAllow: Array<AllowedFormCharacters | string>, character: string) => {
  if (onlyAllow && onlyAllow.length && character) {
    let isValid = false;
    onlyAllow.forEach((allowed: AllowedFormCharacters | string) => {
      switch (allowed) {
        case AllowedFormCharacters.NUMBERS:
          if (character.match(/[0-9]/gi)) {
            isValid = true;
          }
          break;
        case AllowedFormCharacters.LETTERS:
          if (character.match(/[a-zA-Z]/gi)) {
            isValid = true;
          }
          break;
        default:
          if (allowed.includes(character)) {
            isValid = true;
          }
      }
    });

    return isValid;
  }

  return true;
};

export const getImageCdnUrl = (url: string) =>
  url.replace(/^(((http[s]?):\/)|\/\/)?\/?([^:\/\s]+)/, 'https://cfimages.mercuryholidays.co.uk');

export function sec2time(timeInSeconds: number) {
  const pad = (num: number, size: number) => {
    return `000${num}`.slice(size * -1);
  };
  const hours = Math.floor(timeInSeconds / 60 / 60);
  const minutes = Math.floor(timeInSeconds / 60) % 60;

  return `${pad(hours, 2)}h ${pad(minutes, 2)}m`;
}

export const getLabelText = (text: string | Localisation, t: any) => {
  if (typeof text === 'string') {
    return t(text);
  }

  return t(text.label, text.vars);
};

export const getQueryAsObject = (urlQuery: string): Keyable => {
  if (typeof Object.fromEntries === 'function') {
    return Object.fromEntries(new URLSearchParams(urlQuery) as any);
  }
  return urlQuery
    .replace(/^\?/, '')
    .split('&')
    .reduce((acc, current) => {
      const [key, val] = current.split('=');
      return { ...acc, [key]: val };
    }, {});
};

export const getQueryAsString = (query: Keyable): string => {
  return Object.entries(query)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
};

export const sanitiseParams = (query: Keyable): Keyable => {
  const sanitised = mapValues(query, (val, key) => {
    switch (key) {
      case 'adults': {
        const parsedVal = parseInt(val);
        return isNaN(parsedVal) ? '' : `${parsedVal}`;
      }
      case 'destinations': {
        return /^[\w\-,]/.test(decodeURIComponent(val)) ? val : '';
      }
      case 'boardBasis':
        return val in BoardBasis ? val : '';
      case 'tripType':
        return Object.values(TripTypes).includes(val) ? val : '';
    }
    return val;
  });
  return sanitised;
};

export const ConditionalWrapper = ({
  condition,
  wrapper,
  children
}: {
  condition: boolean;
  wrapper: (children: ReactElement) => ReactElement;
  children: ReactElement;
}) => (condition ? wrapper(children) : children);
