import { isEqual } from 'lodash';
import { FormikValues } from 'formik';
import { GuestFormValues, RadioToggleOptions } from '@model/forms';
import { GuestType } from '@model/iceberg/booking/basket/guest/guest';
import { Validation } from '@model/forms/validation/validation';

export interface ValidatedGuestForm {
  errors: {
    firstName?: string;
    lastName?: string;
    title?: string;
    dob: {
      dayOfBirth?: string;
      monthOfBirth?: string;
      yearOfBirth?: string;
    };
  };
  isValid: boolean;
}

export const isInitialGuestEmpty = (guest: GuestFormValues) => {
  const {
    title,
    firstNames,
    lastName,
    dob: { dayOfBirth, monthOfBirth, yearOfBirth },
    email,
    telephone,
    isVegetarian,
    isVegan,
    isDiabetic,
    isGlutenFree,
    dietaryRequirements,
    hasMobilityRequirements,
    hasDietaryRequirements,
    type
  } = guest;
  const isDobEmpty: boolean = type === GuestType.ADULT ? !dayOfBirth && !monthOfBirth && !yearOfBirth : true;
  return (
    !title &&
    !firstNames &&
    !lastName &&
    isDobEmpty &&
    !email &&
    !telephone &&
    !isVegetarian &&
    !isVegan &&
    !isDiabetic &&
    !isGlutenFree &&
    !dietaryRequirements &&
    hasMobilityRequirements === RadioToggleOptions.NO &&
    hasDietaryRequirements === RadioToggleOptions.NO
  );
};

export function validateGuestForm(values: FormikValues): ValidatedGuestForm {
  const errors = getGuestErrors(values);

  const validFormErrors = {
    dob: {}
  };
  if (values.lead) {
    if (!values.email) {
      errors.email = 'form__field-error--required';
    } else if (!Validation.isEmailValid(values.email)) {
      errors.email = 'form__field-error--email';
    }

    if (!values.telephone) {
      errors.telephone = 'form__field-error--required';
    } else {
      const validation = Validation.isTelephoneValidDetails(values.telephone);
      if (!validation.lengthValid) {
        errors.telephone = 'form__field-error--telephone-length';
      } else if (!validation.formatValid) {
        errors.telephone = 'form__field-error--telephone';
      }
    }
  }
  const isValid = isEqual(validFormErrors, errors);
  return { errors, isValid };
}

export function validateAgentGuestForm(values: FormikValues): ValidatedGuestForm {
  const errors = getGuestErrors(values);
  const validFormErrors = {
    dob: {}
  };
  const isValid = isEqual(validFormErrors, errors);
  return { errors, isValid };
}

export function getGuestErrors(values: FormikValues): any {
  const errors: any = {
    dob: {}
  };
  if (!values.title) {
    errors.title = 'form__field-error--required';
  }

  if (!values.firstNames) {
    errors.firstNames = 'form__field-error--required';
  }

  if (!values.lastName) {
    errors.lastName = 'form__field-error--required';
  }

  if (!values.dob.dayOfBirth) {
    errors.dob.dayOfBirth = 'form__field-error--required';
  } else if (
    isNaN(parseInt(values.dob.dayOfBirth)) ||
    parseInt(values.dob.dayOfBirth) < 1 ||
    parseInt(values.dob.dayOfBirth) > 31
  ) {
    errors.dob.dayOfBirth = 'form__field-error--incorrect-value';
  }

  if (!values.dob.monthOfBirth) {
    errors.dob.monthOfBirth = 'form__field-error--required';
  } else if (
    isNaN(parseInt(values.dob.monthOfBirth)) ||
    parseInt(values.dob.monthOfBirth) < 1 ||
    parseInt(values.dob.monthOfBirth) > 12
  ) {
    errors.dob.monthOfBirth = 'form__field-error--incorrect-value';
  }

  if (!values.dob.yearOfBirth) {
    errors.dob.yearOfBirth = 'form__field-error--required';
  } else if (
    isNaN(parseInt(values.dob.yearOfBirth)) ||
    parseInt(values.dob.yearOfBirth) < 1900 ||
    parseInt(values.dob.yearOfBirth) > new Date().getFullYear()
  ) {
    errors.dob.yearOfBirth = 'form__field-error--incorrect-value';
  }
  return errors;
}
