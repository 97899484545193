import axios, { AxiosResponseTransformer } from 'axios';
import { Logging } from '@util/logging';
import { RequestCache } from 'node-fetch';
import { HttpMethod } from '../http-method';
import { EnvConfig } from '@model/config/env-config';
import { AppError, CustomError } from '@model/common';
import Cookies, { Cookie } from '@model/common/cookie/cookie';

export interface PollOptions {
  maxAttempts: number;
  interval: number;
  validityTest?: () => boolean;
  retryIf?: (response: any) => boolean;
  authToken?: string;
}

export interface GetOptions {
  suppressErrorLogs: boolean;
}

const POLL_DEFAULTS: PollOptions = {
  maxAttempts: 10,
  interval: 1000
};

export class Request {
  public async get(url: string, authToken?: string, options?: GetOptions): Promise<any> {
    return await axios
      .get(url, this.getRequestParams(HttpMethod.GET, authToken))
      .then(this.handleResponse)
      .catch((err) => this.handleError(err, options));
  }

  public async post(url: string, data = {}): Promise<any> {
    return await axios
      .post(url, data, this.getRequestParams(HttpMethod.POST))
      .then(this.handleResponse)
      .catch(this.handleError);
  }

  public async put(url: string, data = {}): Promise<any> {
    return await axios
      .put(url, data, this.getRequestParams(HttpMethod.PUT))
      .then(this.handleResponse)
      .catch(this.handleError);
  }

  public async delete(url: string, authToken?: string): Promise<any> {
    return await axios
      .delete(url, this.getRequestParams(HttpMethod.DELETE, authToken))
      .then(this.handleResponse)
      .catch(this.handleError);
  }

  public async wait(ms: number): Promise<any> {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  public async poll(query: string, options?: PollOptions): Promise<any> {
    const { maxAttempts, interval, validityTest, retryIf, authToken } = options || {};
    const requestParams = authToken ? this.getRequestParams(HttpMethod.GET, authToken) : undefined;
    let response = await axios.get(query, requestParams);
    let retries: number = 0;
    const { statusText, status } = response;
    const retryIfTest = retryIf ? retryIf : (response) => response.status === 202;

    while (retryIfTest(response)) {
      retries++;
      if (retries <= (maxAttempts || POLL_DEFAULTS.maxAttempts) && (!validityTest || validityTest())) {
        Logging.log({
          text: `Waiting ${interval || POLL_DEFAULTS.interval}ms before retrying`,
          url: query
        });
        await this.wait(interval || POLL_DEFAULTS.interval);
        response = await axios.get(query, requestParams);
      } else {
        Logging.log({
          text: 'Max polling limit reached'
        });
        return Promise.reject('Max limit');
      }
    }
    Logging.log({
      text: statusText,
      statusCode: status,
      url: query,
      data: response.data
    });
    return response.data;
  }

  public async invokeError(error: any, options?: GetOptions): Promise<AppError> {
    return this.handleError(error, options);
  }

  private async handleResponse(response: any): Promise<any> {
    const { statusText, status, url } = response;
    const isSuccess: boolean = status >= 200 && status < 300 && !response.data?.error;

    if (isSuccess) {
      return response.data;
    }

    Logging.error({
      text: statusText,
      statusCode: status,
      url,
      data: response
    });
    return Promise.reject(new CustomError({ errorCode: response.status, errorText: response.statusText }));
  }

  private async handleError(error: any, options?: GetOptions): Promise<AppError> {
    const { message, errorCode, errorText, statusText, status, response } = error;
    const { data, status: responseStatus, statusText: responseStatusText, headers } = response || {};

    let rejectText = errorText || statusText || responseStatusText;
    let rejectCode = errorCode || status || responseStatus;
    if (message) {
      try {
        const { code, exceptions, identifier } = JSON.parse(message);
        rejectCode = code;
        const exceptionText =
          typeof exceptions === 'object'
            ? Object.values(exceptions).join(', ')
            : (exceptions || identifier || '').toString();
        rejectText = `Error code: ${code}; ${exceptionText}`;
      } catch {
        rejectText = message;
      }
    }
    if (!options || !options?.suppressErrorLogs) {
      Logging.error({
        text: errorText,
        statusCode: status,
        data: error
      });
    }

    return Promise.reject(
      new CustomError({
        errorCode: rejectCode,
        errorText: rejectText,
        response: { status: status || responseStatus, statusText: statusText || responseStatusText, data, headers }
      })
    );
  }

  private getRequestParams(method: HttpMethod, token?: string) {
    const authorization: string | undefined = new Cookies().get(Cookie.TRADE_AUTHORIZATION);
    const authToken = token || authorization;

    return {
      method,
      mode: 'cors' as RequestMode,
      cache: 'no-cache' as RequestCache,
      credentials: 'same-origin' as RequestCredentials,
      redirect: 'follow' as RequestRedirect,
      referrer: 'no-referrer',
      withCredentials: true,
      headers: {
        'X-BrandCode': EnvConfig.get().APP_VARIANT_ID,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...(authToken ? { Authorization: `Bearer ${authToken}` } : {})
      },
      transformResponse: [
        (data) => {
          if (typeof data !== 'string') {
            const { error, code, message } = data;
            if (error && error.code) {
              throw Error(JSON.stringify(error));
            } else if (code && message) {
              throw Error(JSON.stringify({ code, message }));
            }
            return data;
          }
          return data;
        },
        ...(axios.defaults.transformResponse as AxiosResponseTransformer[])
      ]
    };
  }
}
