import { EnvConfig } from '@model/config/env-config';

export enum RequestType {
  CONTENT = 'content',
  IMAGES = 'images'
}

export const getURL = (type?: RequestType) => {
  switch (type) {
    case RequestType.CONTENT:
      return EnvConfig.get().API_CONTENT_URL;
    case RequestType.IMAGES:
      return EnvConfig.get().API_IMAGES_URL;
  }
  return EnvConfig.get().API_URL;
};
