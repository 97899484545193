import { GlobalAppState } from '@model/state';
import { INITIAL_BASKET_STATE } from '@state/basket';
import { INITIAL_FLIGHTS_STATE } from '@state/search-results/flights';
import { INITIAL_EARLY_FLIGHTS_STATE } from '@state/search-results/early-flights';
import { INITIAL_FLIGHTS_SORT_STATE } from '@state/flights-sort';
import { INITIAL_RECENTLY_VIEWED_STATE } from '@state/recently-viewed';
import { INITIAL_COMMON_SEARCH_STATE } from '@state/search';
import rootSaga from '@state/store/combineSagas';
import { isServer } from '@util/is-server';
import { applyMiddleware, legacy_createStore as createStore, Store } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { NAVIGATION_INITIAL_STATE } from '@state/navigation';
import { CMS_INITIAL_STATE } from '@state/cms';
import { persistedRootReducer, rootReducer } from './combineReducers';
import createSagaMiddleware from 'redux-saga';
import { FILTERS_INITIAL_STATE } from '@state/filters';
import { INITIAL_GUESTS_STATE } from '@state/guests/guestOperations';
import { INITIAL_MODAL_STATE } from '@state/modal/modalOperations';
import { INITIAL_TOUR_SEARCH_STATE } from '@state/search';
import { INITIAL_TOURS_STATE } from '@state/search-results/tours/toursOperations';
import { INITIAL_EXTRAS_STATE } from '@state/extras';
import { INITIAL_EXTRAS_OPTIONS_STATE } from '@state/extras-options';
import { INITIAL_SEATMAPS_STATE } from '@state/seat-maps';
import { createWrapper } from 'next-redux-wrapper';
import { INITIAL_COMPONENTS_DATA_STATE } from '@state/components/componentsDataOperations';
import {
  INITIAL_LOADING_STATE,
  INITIAL_PATH_STATE,
  INITIAL_HISTORY_STATE,
  INITIAL_CMS_CONFIG_ID_STATE,
  INITIAL_PACKAGE_REFERENCES_STATE,
  INITIAL_PROMO_MODAL_STATE,
  INITIAL_SHARE_LINK_STATE,
  INITIAL_UI_STATE
} from '@state/app';
import { INITIAL_PROMO_CODE_STATE } from '@state/promo-code/promoCodeOperations';
import { INITIAL_PAYMENT_FORM_STATE } from '@state/payment/paymentFormOperations';
import { INITIAL_DESTINATION_STATE } from '@state/app/destination/destinationOperations';
import { INITIAL_HOTEL_STATE } from '@state/hotel/hotelOperations';
import { INITIAL_PRICE_CALENDAR_FILTERS_STATE } from '@state/price-calendar/filter/priceCalendarFilterOperations';
import { INITIAL_PRICE_CALENDAR_MONTHLY_STATE } from '@state/price-calendar/monthly/priceCalendarMonthlyOperations';
import { INITIAL_FROM_PRICE_PACKAGES_STATE } from '@state/search-results/from-price-packages/fromPricePackagesOperations';
import { MMB_INITIAL_STATE } from '@state/mmb';
import { INITIAL_AGENT_STATE } from '@state/agent/agentOperations';
import { INITIAL_DEAL_FINDER_STATE } from '@state/deal-finder/dealFinderOperations';
import { INITIAL_DEAL_FINDER_RESULTS_STATE } from '@state/deal-finder-results/dealFinderResultsOperations';
import { INITIAL_ALGOLIA_STATE } from '@state/algolia/algoliaOperations';
import { INITIAL_DEAL_FINDER_LAST_SEARCHED_STATE } from '@state/deal-finder-last-searched/dealFinderLastSearchedOperations';
import { INITIAL_MEDIA_POST_STATE } from '@state/agent/social-post/socialMediaPostsOperations';
import { INITIAL_PROMO_BANNER_STATE } from '@state/promo-banner/promoBannerOperations';
import {
  INITIAL_TOUR_REFERENCES_STATE,
  INITIAL_TOUR_AVAILABILITY_STATE,
  INITIAL_TOUR_FILTERS_STATE,
  INITIAL_TOUR_DOSSIERS_STATE,
  INITIAL_TOUR_SUMMARY_STATE
} from '@state/tours';
import { INITIAL_DESTINATION_FAQ_STATE } from '@state/destination-faq';
import { INITIAL_BOOKING_DOCUMENTS_STATE } from '@state/mmb/documents';
import { INITIAL_PAYMENT_ERROR_STATE } from '@state/app/payment-error';
import { INITIAL_FOOTER_STATE } from '@state/content';

export const INITIAL_GLOBAL_APP_STATE: GlobalAppState = {
  navigation: NAVIGATION_INITIAL_STATE,
  basket: INITIAL_BASKET_STATE,
  guests: INITIAL_GUESTS_STATE,
  extras: INITIAL_EXTRAS_STATE,
  extrasOptions: INITIAL_EXTRAS_OPTIONS_STATE,
  seatMaps: INITIAL_SEATMAPS_STATE,
  cms: CMS_INITIAL_STATE,
  filters: FILTERS_INITIAL_STATE,
  searchResults: {
    flights: INITIAL_FLIGHTS_STATE,
    earlyFlights: INITIAL_EARLY_FLIGHTS_STATE,
    tours: INITIAL_TOURS_STATE,
    fromPrices: INITIAL_FROM_PRICE_PACKAGES_STATE
  },
  search: {
    common: INITIAL_COMMON_SEARCH_STATE,
    tours: INITIAL_TOUR_SEARCH_STATE
  },
  flightsSort: INITIAL_FLIGHTS_SORT_STATE,
  footer: INITIAL_FOOTER_STATE,
  app: {
    path: INITIAL_PATH_STATE,
    history: INITIAL_HISTORY_STATE,
    destination: INITIAL_DESTINATION_STATE,
    modal: INITIAL_MODAL_STATE,
    loading: INITIAL_LOADING_STATE,
    cmsConfigId: INITIAL_CMS_CONFIG_ID_STATE,
    packageReferences: INITIAL_PACKAGE_REFERENCES_STATE,
    promoModal: INITIAL_PROMO_MODAL_STATE,
    shareLink: INITIAL_SHARE_LINK_STATE,
    paymentError: INITIAL_PAYMENT_ERROR_STATE,
    uiState: INITIAL_UI_STATE
  },
  recentlyViewed: INITIAL_RECENTLY_VIEWED_STATE,
  componentsData: INITIAL_COMPONENTS_DATA_STATE,
  promoCode: INITIAL_PROMO_CODE_STATE,
  paymentForm: INITIAL_PAYMENT_FORM_STATE,
  hotel: INITIAL_HOTEL_STATE,
  agent: INITIAL_AGENT_STATE,
  dealFinder: INITIAL_DEAL_FINDER_STATE,
  dealFinderResults: INITIAL_DEAL_FINDER_RESULTS_STATE,
  dealFinderLastSearched: INITIAL_DEAL_FINDER_LAST_SEARCHED_STATE,
  priceCalendar: {
    filters: INITIAL_PRICE_CALENDAR_FILTERS_STATE,
    monthly: INITIAL_PRICE_CALENDAR_MONTHLY_STATE
  },
  mmb: MMB_INITIAL_STATE,
  algolia: INITIAL_ALGOLIA_STATE,
  mediaPost: INITIAL_MEDIA_POST_STATE.posts,
  mediaPostTripTypes: INITIAL_MEDIA_POST_STATE.tripTypes,
  promoBanner: INITIAL_PROMO_BANNER_STATE.data,
  tours: {
    tourReferences: INITIAL_TOUR_REFERENCES_STATE,
    tourAvailability: INITIAL_TOUR_AVAILABILITY_STATE,
    tourFilters: INITIAL_TOUR_FILTERS_STATE,
    tourDossiers: INITIAL_TOUR_DOSSIERS_STATE,
    tourSummary: INITIAL_TOUR_SUMMARY_STATE
  },
  destinationFaq: INITIAL_DESTINATION_FAQ_STATE,
  mmbDocuments: INITIAL_BOOKING_DOCUMENTS_STATE
};

export function createReduxStore(initialState: GlobalAppState = INITIAL_GLOBAL_APP_STATE) {
  const sagaMiddleware = createSagaMiddleware();
  const reducer: any = isServer ? rootReducer : persistedRootReducer;
  const store: any = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(sagaMiddleware)));

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
}

const makeConfiguredStore = (reducer: any) => {
  const sagaMiddleware = createSagaMiddleware();
  const store: any = createStore(reducer, undefined, composeWithDevTools(applyMiddleware(sagaMiddleware)));
  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export const makeStore: any = () => {
  if (isServer) {
    return makeConfiguredStore(rootReducer);
  }

  // we need it only on client side
  const { persistStore } = require('redux-persist');

  const store: any = makeConfiguredStore(persistedRootReducer);

  store.__persistor = persistStore(store); // Nasty hack

  return store;
};

export const storeWrapper = createWrapper<Store<GlobalAppState>>(makeStore);
