export const colorGroups = {
  group1: {
    lighter: '#effeff', // background colours on interactive, 14
    light: '#c6eef0', // hover background colours, 7
    base: '#31b7bc', // icons, selected interactive, > 100
    dark: '#218084', // icon hover, 4
    darker: '#218084' // some icon hover, 2
  },
  group2: {
    lighter: '#fbfbfb', // disabled buttons, container bg, 5
    light: '#bce4f1', // toggle checked bg, disabled destination hover colour, 2
    base: '#005672', // interactive text colour, links, 30
    dark: '#003c4f', // icon hover colours, 10
    darker: '#1b3744' // cookies banner accept cta, 1 file
  },
  group3: {
    lighter: '#ccede9', // unused
    light: '#67c9be', // unused
    base: '#02a694', // info tile icons, 1
    dark: '#016358', // unused
    darker: '#00423b' // countdown banner, 1
  },
  group4: {
    lighter: '#c4c4c4', // backgrounds, borders, 30
    light: '#595959', // label, icon colour, 72
    base: '#404040', // default text colour, > 100
    dark: '#262626', // mobile price labels, 4
    darker: '#191919' // mobile nav divider bg, 1
  },
  group5: {
    lighter: '#d1f1dc', // calendar hover, pagination selected, 9
    light: '#76d598', // unused
    base: '#1cb954', // interactive selected colour, 40
    dark: '#106f32', // tertiary button, 1
    darker: '#0b4a21' // unused
  },
  group6: {
    lighter: '#f4fbf8', // colourCard mixin, 3
    light: '#bee7d6', // colourCard mixin, 1
    base: '#9bddc4', // tour review icon, 1
    dark: '#d9f3e8', // product review % bg, 1
    darker: '#5b0032' // unused
  },
  group7: {
    lighter: '#cdf3fc', // price calendar day bg, what happens next ng, 2
    light: '#6adbf8', // price calendar day border, 2
    base: '#07c4f4', // generic border , 37
    dark: '#047592', // unused
    darker: '#024e61' // unused
  },
  group8: {
    lighter: '#fbf0cc', // small pill, multi leg, no results bg, 5
    light: '#f5d367', // offer, pill background, no results border, 4
    base: '#efb702', // product offer, tour badge, 3
    dark: '#8f6d01', // unused
    darker: '#5f4900' // unused
  },
  group9: {
    lighter: '#fce7cc', // unused
    light: '#f7b868', // unused
    base: '#f38904', // footer links, cookies hover, star, 9
    dark: '#915202', // unused
    darker: '#613601' // unused
  },
  group10: {
    lighter: '#f2f2f2', // generic backgrounds, 50
    light: '#e5e5e5', // generic borders, > 100
    base: '#bfbfbf', // box shadows, interactives, 96
    dark: '#7f7f7f', // box shadows, sub labels, 18
    darker: '#191919' // icon hover colour, 5
  },
  group11: {
    lighter: '#fff3f4', // disabled hover and background styles, 4
    light: '#ff767b', // unused
    base: '#ff0000', // bold heading display, clear, error,  27
    dark: '#991015', // clear search/ calendar hover styles
    darker: '#330507' // unused
  },
  group12: {
    // unused
    lighter: '#ddcddb', // unused
    light: '#9a6992', // unused
    base: '#57054a', // unused
    dark: '#34032c', // unused
    darker: '#23021e' // unused
  }
};
